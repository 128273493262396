import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { withRouter } from "../../utils/with-router.js";
import AuthService from "../../services/auth.service";
import ImageService from "../../services/image.service";
import UserService from "../../services/user.service";
import ImageCropper from "./ImageCropper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Container, Typography, ButtonGroup, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForeverOutlined";

function MyBackgrounds({ user, backgroundSelected, setLoading, openSnackbar, setSelectedBackground }) {
  const [currentUser, setCurrentUser] = useState(user);
  const [searchTerm, setSearchTerm] = useState("");

  const [pixaImages, setPixaImages] = useState([]);
  const [pixaPage, setPixaPage] = useState(1);
  const [pixaPageCount, setPixaPageCount] = useState(0);

  const [popupOpen, setPopupOpen] = useState(false);
  const [backgrounds, setBackgrounds] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [unsplashImages, setUnsplashImages] = useState([]);
  const [unsplashPage, setUnsplashPage] = useState(1);
  const [unsplashPageCount, setUnsplashPageCount] = useState(0);

  const [unsplashHidden, setUnsplashHidden] = useState(false);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      AuthService.getCurrentUser().then((currentUser) => {
        setCurrentUser(currentUser);
        getUserBackgrounds(currentUser.id);
      });
      getPixabayImages();
      getUnsplashImages();
    }
  });

  const getUserBackgrounds = (id) => {
    UserService.getUserBackgrounds(id).then((backgrounds) => {
      var images = [];
      backgrounds.forEach((element) => {
        images.push({
          src: element.url,
          height: 1,
          width: 1,
        });
      });
      setBackgrounds(images);
    });
  };

  const getPixabayImages = (page) => {
    ImageService.getPixabayImages(searchTerm, page)
      .then((result) => {
        setPixaPageCount(Math.round(result.total / 30));
        setPixaImages(setImages(result.hits));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUnsplashImages = (page) => {
    ImageService.getUnsplashImages(searchTerm, page).then((response) => {
      const pagecount = response.response.total / 30;
      setUnsplashPageCount(Math.round(pagecount));
      setUnsplashImages(setImages(response.response.results));
    });
  };

  const searchImages = () => {
    setPixaPage(1);
    setUnsplashPage(1);
    getPixabayImages(1);
    getUnsplashImages(1);
  };
  const setImages = (imgs) => {
    var images = [];
    imgs.forEach((element) => {
      element.tags = [];
      element.src = element.previewURL ? element.previewURL : element.urls.thumb;
      element.width = element.previewWidth ? element.previewWidth : element.width;
      element.height = element.previewHeight ? element.previewHeight : element.height;
      element.lrgsrc = element.largeImageURL ? element.largeImageURL : element.urls.regular;
      images.push(element);
    });
    return images;
  };

  const imageSelected = (e) => {
    console.log(e.target.attributes);
    const bg = e.target.attributes.lrgsrc.value;
    setSelectedImage(bg);
    setPopupOpen(true);
    localStorage.setItem("selectedBackgroundURL", bg);
  };
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const saveCroppedBackground = (croppedImage) => {
    setLoading(true);
    ImageService.saveCroppedBackground(currentUser.id, croppedImage).then((response) => {
      if (response == "MaxLimit") {
        openSnackbar("error", "Max background limit reached (10)");
        closePopup();
        setLoading(false);
        return;
      }
      getUserBackgrounds(currentUser.id);
      closePopup();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      setLoading(false);
    });
  };

  const applyCroppedBackground = (croppedImage) => {
    console.log(croppedImage);
    setSelectedBackground(croppedImage);
    closePopup();
  };

  const changePixaPage = (event, value) => {
    setPixaPage(value);
    getPixabayImages(value);
  };
  const changeUnsplashPage = (event, value) => {
    setUnsplashPage(value);
    getUnsplashImages(value);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const deleteBackground = (background) => {
    ImageService.deleteBackground(currentUser.id, background.src).then(() => getUserBackgrounds(currentUser.id));
  };
  const [file, setFile] = useState();
  useEffect(() => {
    if (!file) return;

    try {
      Resizer.imageFileResizer(
        file.file,
        1000,
        1000,
        "WEBP",
        100,
        0,
        (uri) => {
          if (typeof uri === "string") {
            console.log(uri);
            setSelectedBackground(uri);
            setLoading(false)
          }
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  }, [file]);
  const fileUploadHandler = async (e) => {
    if (!e.target.files?.length) {
      return;
    }

    const image = e.target.files[0];

    setFile({ file: image, name: image.name });

    setLoading(true);
  };

  return (
    <Container>
      {backgrounds.length > 0 && (
        <div>
          <Typography variant="h5" fontWeight={500}>
            My Backgrounds
          </Typography>
          <ImageList cols={mobile ? 3 : 5}>
            {backgrounds?.map((item, index) => (
              <ImageListItem key={index}>
                <img
                  className="imageitems"
                  onClick={backgroundSelected}
                  src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  }}
                  title={item.title}
                  position="top"
                  actionIcon={
                    <IconButton
                      onClick={() => deleteBackground(item)}
                      sx={{ color: "white", padding: "3px" }}
                      aria-label={`delete ${item.title}`}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}

      <div className="center-div" style={{ position: "sticky", zIndex: 100, top: 0 }}>
        <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <InputBase
            fullWidth
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search for backgrounds..."
            onChange={handleChange}
            inputProps={{ "aria-label": "search products" }}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={searchImages}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <Box id="stock-images">
        <ButtonGroup>
          <Button variant={unsplashHidden ? "text" : "contained"} onClick={() => setUnsplashHidden(false)}>
            Unsplash
          </Button>
          <Button variant={!unsplashHidden ? "text" : "contained"} onClick={() => setUnsplashHidden(true)}>
            Pixabay
          </Button>
          <Button variant={!unsplashHidden ? "text" : "contained"} onClick={() => setUnsplashHidden(true)}>
            DALLE3
          </Button>          
          <Button variant={"text"} component="label">
            <input type="file" hidden accept="image/*" onChange={fileUploadHandler} />
            Upload
          </Button>
        </ButtonGroup>
        <Box id="unsplash" hidden={unsplashHidden}>
          <ImageList cols={mobile ? 3 : 5} gap={8}>
            {unsplashImages?.map((item, index) => {
              return (
                <ImageListItem key={index} onClick={imageSelected}>
                  <img
                    className="imageitems"
                    src={`${item.src}`}
                    srcSet={`${item.src}`}
                    alt={item.title}
                    loading="lazy"
                    lrgsrc={item.lrgsrc}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <Pagination count={unsplashPageCount} page={unsplashPage} onChange={changeUnsplashPage} />
        </Box>
        <Box id="pixabay" hidden={!unsplashHidden}>
          <ImageList cols={mobile ? 3 : 5} gap={8}>
            {pixaImages?.map((item, index) => (
              <ImageListItem key={index} onClick={imageSelected}>
                <img
                  className="imageitems"
                  src={`${item.src}`}
                  srcSet={`${item.src}`}
                  alt={item.title}
                  loading="lazy"
                  lrgsrc={item.largeImageURL}
                />
              </ImageListItem>
            ))}
          </ImageList>
          <Pagination count={pixaPageCount} page={pixaPage} onChange={changePixaPage} />
        </Box>
      </Box>
      <Modal
        open={popupOpen}
        onClose={closePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <ImageCropper
            image={selectedImage}
            applyCroppedBackground={applyCroppedBackground}
            saveCroppedBackground={saveCroppedBackground}
            closePopup={closePopup}
          />
        </Box>
      </Modal>
    </Container>
  );
}

function downloadImage(downloadName) {
  let tempLink = document.createElement("a");
  tempLink.download = downloadName;
  tempLink.href = document.getElementById("canvas").toDataURL("image/jpeg", 0.9);
  tempLink.click();
}

export default withRouter(MyBackgrounds);
