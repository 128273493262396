import React, { useState, useEffect } from "react";
import { withRouter } from "../../../utils/with-router";
import productService from "../../../services/product.service";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import RestartIcon from "@mui/icons-material/RestartAlt";
import { Stack, Typography, ButtonGroup, Tooltip } from "@mui/material";
import openaiService from "../../../services/openai.service";
import ImageGallery from "react-image-gallery";
import imageService from "../../../services/image.service";

const EditCategoryPopup = (props) => {
  const [catName, setCatName] = useState("");
  const [catDescription, setCatDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [productImages, setProductImages] = useState([]);

  useEffect(() => {
    if (props.selectedCategory) {
      const category = props.selectedCategory;
      console.log({ category });
      setCatName(category.name);
      setCatDescription(category.description);
      if (category?.image?.src) setSelectedImage(category.image.src);
      getProducts();
    }
  }, [props.selectedCategory]);

  const catNameChanged = (e) => {
    setCatName(e.target.value);
  };

  const catDescriptionChanged = (e) => {
    setCatDescription(e.target.value);
  };

  const addCategory = () => {
    const data = {
      id: props.selectedCategory.id,
      name: catName,
      description: catDescription,
      image: selectedImage,
    };

    productService.saveCategory(props.currentUser.id, data).then((response) => {
      console.log({ response });
    });
  };

  const [products, setProducts] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const getProducts = () => {
    productService
      .getProductsByCategory(props.currentUser.id, perPage, pageNumber, props.selectedCategory.id)
      .then((response) => {
        if (response) {
          const products = response.Products;
          console.log({ products });
          const filteredAndSortedProducts = products
            .filter((product) => product.stock_status === "instock")
            .sort((a, b) => b.stock_quantity - a.stock_quantity);

          setProducts(filteredAndSortedProducts);

          if (products.length < response.TotalCount) {
            setLoadMore(true);
          } else {
            setLoadMore(false);
          }
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancel = () => {
    props.closePopup();
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductChange = (event, newValue) => {
    setSelectedProducts(newValue);
  };

  // Function to generate suggested description using AI or predefined logic
  const generateSuggestedDescription = () => {
    // Logic to generate the suggested description goes here
    // For example, fetching suggested description from an API or AI service
    // Update the 'suggestedDescription' state with the fetched description
    const category = props.selectedCategory;

    let prompt = "Category name: " + catName;
    if (category.children.length) {
      const children = category.children.map((child) => child.name);
      const childrenString = children.join(", ");
      prompt = prompt + ". Category Children: " + childrenString;
    }
    if (selectedProducts.length) {
      const productNames = selectedProducts.map((product) => product.name);
      const productNamesString = productNames.join(", "); // Convert array of product names to a comma-separated string
      prompt = prompt + ". Products: " + productNamesString;
    }
    openaiService.writeCategoryDescription(prompt).then((response) => {
      console.log({ response });
      setCatDescription(response.data.result + " " + catDescription);
    });
  };

  const getImages = () => {
    const allImages = products.flatMap((product) => product.images);

    let galleryImages = [];
    if (props.selectedCategory?.image?.src) {
      const url = props.selectedCategory.image.src;
      galleryImages.push({ original: url, thumbnail: url });
    }
    for (const product of products) {
      for (const image of product.images) {
        const url = image.src;
        galleryImages.push({ original: url, thumbnail: url });
      }
    }

    setProductImages(galleryImages);
    console.log({ allImages });
  };

  useEffect(() => {
    if (!products?.length) return;
    console.log({ products });
    getImages();
  }, [products]);

  const gallerySlide = (e) => {
    const imageURL = productImages[e].original;
    setSelectedImage(imageURL);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ImageGallery items={productImages} showPlayButton={false} onSlide={gallerySlide} />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label="Category Name"
            variant="outlined"
            value={catName}
            onChange={catNameChanged}
            placeholder="Category Name..."
          />
          <Stack direction="row" sx={{ mb: 1 }}>
            <Typography variant="h5" fontWeight={500} width={120}>
              Description
            </Typography>
            <ButtonGroup>
              <Tooltip title="Suggest Description">
                <Button size="small" variant="contained" onClick={generateSuggestedDescription}>
                  <RestartIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Stack>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={catDescription}
            onChange={catDescriptionChanged}
            placeholder="Description..."
            multiline
            rows={8}
          />
          <Autocomplete
            fullWidth
            options={products}
            getOptionLabel={(option) => option.name}
            value={selectedProducts}
            limitTags={5}
            multiple
            onChange={handleProductChange}
            renderInput={(params) => (
              <TextField {...params} label="Products" variant="outlined" placeholder="Select Products..." />
            )}
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px" }}>
        <Button variant="contained" color="secondary" onClick={cancel} fullWidth>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={addCategory} fullWidth style={{ marginTop: "10px" }}>
          Save Category
        </Button>
      </div>
    </div>
  );
};

export default withRouter(EditCategoryPopup);
