import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function AddProductHeader(props) {
  return (
    <Box>
      <Stack sx={{ flexDirection: { xs: "column", md: "row" } }} className="mb-3">
        <Typography variant="h5" sx={{ fontWeight: 600, marginRight: "10px" }}>
          {props.selectedProduct.name}
        </Typography>
        {props.index} / {props.total}
        <Stack direction="row" sx={{ marginLeft: "auto" }}>
          <Typography variant="h6">£{props.selectedProduct.price}</Typography>
          <Typography variant="h5" sx={{ mx: 2 }}>
            {" "}
            |{" "}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: props.selectedProduct.stock > 3 ? "success.main" : "error.light",
              marginRight: 2,
            }}
          >
            Stock: {props.selectedProduct.stock}
          </Typography>
        </Stack>
      </Stack>
      <hr></hr>
    </Box>
  );
}
