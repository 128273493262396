import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar({ snackOpen, severity, message, link, path, onClose }) {
  return (
    <Snackbar open={snackOpen} autoHideDuration={6000} onClose={onClose} >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
        <Link to={path} hidden={link ? false : true} style={{ marginLeft: '10px' }}>
          <Button color="secondary" size="small">
            {link}
          </Button>
        </Link>
      </Alert>
    </Snackbar>
  )
}
