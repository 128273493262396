import React, { Component, useEffect, useState } from "react";

import AuthService from "../../services/auth.service";

import { withRouter } from "../../utils/with-router";

import ProductService from "../../services/product.service";

import WigglerService from "../../services/wiggler-service";
import UserService from "../../services/user.service";

import { Button, Container, Typography, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { CurrentUser } from "../../types/global";
import StockCard from "./stock-card";
import ImportService from "../../services/import.service";

type Product = {
  id: string;
  name: string;
  updated: boolean;
  command: string;
  cStock: number;
  nStock: number;
  image: string;
};

export const ImportManager = () => {
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    AuthService.getCurrentUser().then((currentUser) => {
      setCurrentUser(currentUser);
    });
  }, []);

  const getCategories = () => {
    ImportService.getCategories().then((response) => {
      console.log(response);
    });
  };

  const importProducts = () => {
    ImportService.importProducts(currentUser?.id).then((response) => {
      console.log(response);
    });
  };

  return (
    <Container>
      <div className="stock-check product-item-container">
        <Button variant="contained" onClick={() => importProducts()}>
          Import Products
        </Button>
        <Button variant="contained" onClick={() => getCategories()}>
          Get Categories
        </Button>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </Container>
  );
};
