import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/openai/";

class OpenAIService {
  getKeywordsByText(text) {
    return axios.post(API_URL + "getKeywordsFromText", { headers: authHeader(), text }).then((response) => {
      console.log(response);
      return response;
    });
  }

  getCaptionForProduct(text) {
    return axios.post(API_URL + "getCaptionForProduct", { headers: authHeader(), text }).then((response) => {
      console.log(response);
      return response;
    });
  }

  completeTextFromPrompt(prompt) {
    return axios.post(API_URL + "completeTextFromPrompt", { headers: authHeader(), prompt }).then((response) => {
      console.log(response);
      return response;
    });
  }

  completeChatFromPrompt(prompt) {
    return axios.post(API_URL + "completeChatFromPrompt", { headers: authHeader(), prompt }).then((response) => {
      console.log(response);
      return response;
    });
  }

  writeCategoryDescription(prompt) {
    return axios.post(API_URL + "category-description", { headers: authHeader(), prompt }).then((response) => {
      console.log(response);
      return response;
    });
  }

  getCategorySuggestion(category, categories) {
    return axios.post(API_URL + "category-suggestion", { headers: authHeader(), category, categories }).then((response) => {
      console.log({ response });
    });
  }
}

function removeSpaces(string) {
  const newString = string.replace(/ /g, "");
  return newString;
}

function addHashtag(string) {
  const newString = removeSpaces(string);
  const words = newString.split(",");
  for (let i = 0; i < words.length; i++) {
    words[i] = "#" + words[i];
  }
  return words.join(" ");
}

export default new OpenAIService();
