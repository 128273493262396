import React, { useEffect, useState } from "react";

import mergeImages from "merge-images-v2";
import { ChromePicker } from "react-color";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import ColorLensIcon from "@mui/icons-material/ColorLens";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

import MyBackgrounds from "../MyBackgrounds/MyBackgrounds";

import ImageService from "../../services/image.service";

function EditImage({ currentUser, imageSaved, imageSaving, selectedImage, editing, setEditPending, setLoading }) {
  const [color, setColor] = useState({ r: "255", g: "0", b: "0", a: "1" });
  const [threshold, setThreshold] = useState(250);
  const [edgeBlur, setEdgeBlur] = useState(1);
  const [productImgSrc, setProductImgSrc] = useState(selectedImage);
  const [selectedBackground, setSelectedBackground] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");
  const [remWhiteSettingsOpen, setRemWhiteSettingsOpen] = useState(false);
  const [imageEdited, setImageEdited] = useState(false);

  useEffect(() => {
    if (imageSaving) {
      saveEditedImage();
    }
  }, [imageSaving]);

  useEffect(() => {
    setProductImgSrc(editing ? selectedImage : null);
    setSelectedBackground("");
    setImageEdited(false);
  }, [editing]);

  // editing
  const removeWhite = async () => {
    setLoading(true);
    setImageEdited(true);
    setEditPending();

    ImageService.removeWhite(selectedImage, threshold, edgeBlur).then(async (response) => {
      var img = new Image();
      img.src = response;
      setProductImgSrc(response);
      setLoading(false);
    });
  };
  const removeBackground = async () => {
    setLoading(true);
    setImageEdited(true);
    setEditPending();
    ImageService.removeBackground(selectedImage).then((response) => {
      console.log({ response })
      if (response?.imageSrc) {
        setProductImgSrc(response.imageSrc);
      }
      setLoading(false);
    });
  };
  const saveEditedImage = () => {
    setLoading(true);
    if (selectedBackground == "") mergeColourBackground();
    else {
      mergeImageBackground();
    }
  };
  const backgroundSelected = (e) => {
    var src = e.target.currentSrc;
    if (!imageEdited) {
      removeWhite();
    }

    ImageService.imgToDataUrl(src).then(async (url) => {
      console.log({ url });
      setSelectedBackground(url);
    });
  };
  const mergeImageBackground = () => {
    var img = productImgSrc;
    const bg = selectedBackground;
    mergeImages([
      { src: bg, x: 0, y: 0, width: 1000, height: 1000 },
      { src: img, x: 0, y: 0, width: 1000, height: 1000 },
    ]).then((b64) => {
      imageSaved(b64, true);
    });
  };
  const mergeColourBackground = () => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    var img = document.getElementById("productImg");
    console.log(img);
    var w = 1000;
    var h = 1000;
    canvas.width = w;
    canvas.height = h;

    ctx.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
    ctx.fillRect(0, 0, w, h);
    ctx.drawImage(img, 0, 0, w, h);
    const b64 = canvas.toDataURL("image/jpeg");

    imageSaved(b64, true);
  };
  const undoBackground = () => {
    setSelectedBackground("");
  };
  const openThreshold = (e) => {
    setRemWhiteSettingsOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const blurBackground = () => {
    setLoading(true);
    ImageService.adjustImage(selectedBackground).then((background) => {
      setSelectedBackground(background);
      setLoading(false);
    });
  };
  const applyBackground = (image) => {
    if (!imageEdited) {
      removeWhite();
    }
    setSelectedBackground(image);
  };

  // color picker
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleChange = (color) => {
    if (!imageEdited) removeWhite();
    if (selectedBackground) setSelectedBackground("");
    setColor(color.rgb);
  };

  const colorStyle = {
    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    float: "left",
    position: "relative",
  };
  const imageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 99,
  };
  const backgroundStyle = {
    position: "relative",
    top: 0,
    left: 0,
  };

  return (
    <Box hidden={!editing}>
      <Grid container>
        <Grid item md={7} xs={12}>
          <Box style={colorStyle} sx={{ width: { md: 650, xs: 260 }, height: { md: 650, xs: 260 } }}>
            <img id="productImg" style={imageStyle} src={productImgSrc} width="100%" />
            <img id="backgroundImg" style={backgroundStyle} src={selectedBackground} width="100%" />
          </Box>
          <Box id="colour-picker">
            <Popover
              open={displayColorPicker}
              anchorEl={anchorEl}
              onClose={() => setDisplayColorPicker(false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <ChromePicker disableAlpha={false} onChange={handleChange} color={color} />
            </Popover>
            <ButtonGroup className="my-1">
              <Button
                variant="contained"
                alt="Change Background Colour"
                title="Change Background Colour"
                onClick={handleClick}
              >
                <ColorLensIcon />
              </Button>
              <Button
                variant="contained"
                alt="Remove White Background"
                title="Remove White Background"
                onClick={openThreshold}
              >
                <AutoFixNormalIcon />
              </Button>
              <Button variant="contained" alt="Remove Background" title="Remove Background" onClick={removeBackground}>
                <AutoFixHighIcon />
              </Button>
              <Button
                disabled={selectedBackground ? false : true}
                variant="contained"
                alt="Clear Background"
                title="Clear Background"
                onClick={undoBackground}
              >
                <CancelPresentationIcon />
              </Button>
              <Button
                disabled={selectedBackground ? false : true}
                variant="contained"
                alt="Blur Background"
                title="Blur Background"
                onClick={blurBackground}
              >
                <BlurOnIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box style={{ maxHeight: "650px", overflow: "scroll", overflowX: "hidden" }}>
            <MyBackgrounds
              setLoading={setLoading}
              editImage={true}
              backgroundSelected={backgroundSelected}
              setSelectedBackground={applyBackground}
              user={currentUser}
            />
          </Box>
        </Grid>
      </Grid>
      <Popover
        id="remove-white-popover"
        open={remWhiteSettingsOpen}
        anchorEl={anchorEl}
        onClose={() => setRemWhiteSettingsOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Stack direction="row" sx={{ margin: "10px" }}>
          <TextField
            label="Threshold"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: 255,
              },
            }}
          />
          <TextField
            label="Blur"
            type="number"
            onChange={(e) => setEdgeBlur(e.target.value)}
            value={edgeBlur}
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
              },
            }}
          />
        </Stack>
        <Button variant="contained" alt="Remove White" title="Remove White" onClick={removeWhite}>
          <AutoFixNormalIcon />
        </Button>
      </Popover>
    </Box>
  );
}

export default EditImage;
