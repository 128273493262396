import {
  Box,
  TextField,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Checkbox,
  Button,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import React, { useState } from "react";
import RecipeService from "../../services/recipe.service";

type Ingredient = {
  id: number;
  value: string;
};

export default function SwiftShopper() {
  const [checked, setChecked] = useState<number[]>([]);
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [recipeTitle, setRecipeTitle] = useState("");
  const [inputValue, setInputValue] = useState<string>("");

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAdd = () => {
    // Parse the input value into an array of unique ingredients
    const inputIngredients = inputValue
      .split("\n")
      .map((ingredient) => ingredient.replace(/†/g, "").trim()) // Remove '†' character
      .filter((ingredient, index, self) => {
        return index === self.indexOf(ingredient); // Remove duplicates
      });

    // Create an array of Ingredient objects
    const newIngredients: Ingredient[] = inputIngredients.map((value, index) => ({
      id: index,
      value: value.trim(), // Trim any leading/trailing whitespace
    }));

    // Update the state
    setIngredients((prevIngredients) => [...prevIngredients, ...newIngredients]);
    setInputValue(""); // Clear the input field

    RecipeService.create(recipeTitle, newIngredients).then((response) => {
      console.log({ response });
    });
  };

  return (
    <Box>
      <Typography>Add Recipe</Typography>
      <TextField fullWidth label="Title" value={recipeTitle} onChange={(e) => setRecipeTitle(e.target.value)} />
      <TextField
        fullWidth
        multiline
        rows={5}
        label="Ingredients"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <Button fullWidth onClick={handleAdd}>
        Add
      </Button>
      <Typography>Shopping List</Typography>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {ingredients.map((value) => {
          const labelId = `checkbox-list-label-${value.id}`;

          return (
            <ListItem
              key={value.id}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <CommentIcon />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} onClick={handleToggle(value.id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value.value}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
