import React, { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
import { FormGroup, FormControlLabel, Checkbox, Typography, CircularProgress } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { green } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import FacebookService from "../../services/facebook.service";

import { Post, BaseProps, FacebookPage } from "../../types/global";

type PublishProps = {
  disabled: boolean;
  cancel: () => void;
  publishPost: () => void;
  previewPost: () => void;
  postPublished: boolean;
  publishingPost: boolean;
  fbPages: FacebookPage[];
  setFbPages: (pages: FacebookPage[]) => void;
  queuePost: () => void;
  fbConnected: boolean;
} & BaseProps;

export default function PublishSettings({
  disabled,
  cancel,
  previewPost,
  publishPost,
  postPublished,
  publishingPost,
  queuePost,
  fbPages,
  setFbPages,
  fbConnected,
}: PublishProps) {
  const [published, setPublished] = useState(false);

  useEffect(() => {
    console.log(fbConnected)
    if (fbConnected) getFbPages();
    else setFbPages([]);
  }, [fbConnected]);

  const getFbPages = () => {
    FacebookService.getFacebookPages().then((response) => {
      console.log(response)
      var pages: FacebookPage[] = [];
      response.forEach((element) => {
        pages.push({ id: element.id, name: element.name, publish: false });
      });
      setFbPages(pages);
    });
  };

  const pageSelected = (e) => {
    var pageId = e.target.name;
    var checked = e.target.checked;

    var pages = fbPages.slice();
    pages.forEach((element) => {
      if (element.id == pageId) {
        element.publish = checked;
      }
    });

    setFbPages(pages);
  };

  const buttonSx = {
    width: { xs: "100%", md: "150px" },
    ...(published && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Box>
      <hr></hr>
      <Stack sx={{ flexDirection: { xs: "column", md: "column" } }}>
        <Button
          sx={{ width: "100%", marginBottom: "15px" }}
          variant="contained"
          color="secondary"
          onClick={previewPost}
          disabled={disabled}
          startIcon={<PreviewIcon />}
        >
          Preview Post
        </Button>
        {fbConnected ? (
          <Box>
            <FormGroup>
              <Typography variant="h5">Publish to:</Typography>
              <Stack>
                {fbPages?.map((page) => {
                  console.log();
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={page.id}
                          checked={page.publish}
                          onChange={pageSelected}
                          disabled={disabled}
                        ></Checkbox>
                      }
                      label={page.name}
                    />
                  );
                })}
              </Stack>
            </FormGroup>
          </Box>
        ) : (
          <Typography variant="h6" color="error" style={{ marginRight: "10px", marginBottom: "10px" }}>
            You must be logged in to Facebook to publish this post
          </Typography>
        )}
        <Box className="mt-2">
          <Stack sx={{ flexDirection: { xs: "column", md: "column" } }} id="publish-buttons">
            <Stack direction="row">
              <Button
                sx={{ width: { xs: "50%", md: "150px" }, marginRight: "20px" }}
                variant="outlined"
                color="error"
                onClick={cancel}
                disabled={disabled}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: { xs: "50%", md: "150px" }, marginRight: "20px" }}
                endIcon={<PublishIcon />}
                onClick={queuePost}
              >
                {"Queue"}
              </Button>
              <Box sx={{ width: { xs: "50%", md: "150px" }, position: "relative" }}>
                <Button
                  variant="contained"
                  sx={buttonSx}
                  disabled={fbConnected ? false : true}
                  endIcon={<PublishIcon />}
                  onClick={publishPost}
                >
                  {postPublished ? "Published" : "Publish"}
                </Button>
                {publishingPost && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
