import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/test/";
const USER_API_URL = "/api/user/";
const IMAGE_API_URL = "/api/images/";

class UserService {
  getUserBackgrounds(userId) {
    return axios.get(USER_API_URL + `backgrounds/${userId}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  connectApi(userId, apiUrl, apiKey) {
    return axios
      .post(API_URL + "connect-api", { headers: authHeader(), userId, apiUrl, apiKey })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setInstaId(userId, instaId) {
    return axios.post(USER_API_URL + "insta-id", { headers: authHeader(), userId, instaId }).then((response) => {
      console.log("insta id set: ", response);
    });
  }

  setWooKeys(userId, key, secret) {
    return axios.post(USER_API_URL + "woo-keys", { headers: authHeader(), userId, key, secret }).then((response) => {
      return response;
    });
  }

  setStoreURL(username, url) {
    return axios.post(USER_API_URL + "set-store-url", { headers: authHeader(), url, username }).then((response) => {
      return response;
    });
  }

  setMinStock(userId, minStock) {
    return axios.post(USER_API_URL + "set-min-stock", { headers: authHeader(), userId, minStock }).then((response) => {
      console.log(response);
    });
  }

  connectUserStore(userId, storeUrl) {
    return axios
      .post(USER_API_URL + "connect-user-store", { headers: authHeader(), userId, storeUrl })
      .then((response) => {
        if (response) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.synced = true;
          user.product_count = response.data;

          localStorage.setItem("user", JSON.stringify(user));
        }
        return response.data;
      })
      .catch(function (error) {
        return;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  syncUserStore(userId, storeUrl) {
    return axios
      .post(USER_API_URL + "sync-store", { headers: authHeader(), userId, storeUrl })
      .then((response) => {
        if (response) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.synced = true;
          user.product_count = response.data;

          localStorage.setItem("user", JSON.stringify(user));
        }
        return response.data;
      })
      .catch(function (error) {
        return;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  checkStock(userId) {
    return axios.get(USER_API_URL + `stock/${userId}`, { headers: authHeader() }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  disconnectStore(userId, storeUrl) {
    return axios
      .post(USER_API_URL + "disconnect-store", { headers: authHeader(), userId, storeUrl })
      .then((response) => {
        if (response) {
          return response;
        }
      });
  }

  getUserStores(userId) {
    return axios.get(USER_API_URL + `stores/${userId}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getUserPosts(userId) {
    return axios.get(USER_API_URL + `posts/${userId}`, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  getDraftPosts(userId) {
    return axios.get(USER_API_URL + `draft-posts/${userId}`, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  getUserPostById(userId, postId) {
    return axios.get(USER_API_URL + `post/${userId}/${postId}`, { headers: authHeader() }).then((response) => {
      return response;
    });
  }
}

export default new UserService();
