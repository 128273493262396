import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";

const placeholder = "https://i0.wp.com/tsdheadshop.co.uk/wp-content/uploads/woocommerce-placeholder.png";

export default function CategoryItems(props) {
  let name = props.name;
  const id = props.id;
  const image = props.image;
  const count = props.count;

  if (name) name = name + (count ? ` (${count})` : "");

  const onClick = () => {
    props.click(props);
  };

  return (
    <Card key={id} sx={{ width: { xs: "45%", md: 140 }, margin: "5px" }} className="category-item">
      {name ? (
        <CardActionArea onClick={onClick} disabled={props.loading}>
          <CardMedia
            component="img"
            sx={{ height: { xs: 0, md: 140 } }}
            image={`${image ? image.src : placeholder}?w=164&h=164&fit=crop&auto=format`}
            alt="category image"
            loading="lazy"
          />
          <CardContent>
            <Typography variant="b" component="div">
              {name}
            </Typography>
          </CardContent>
        </CardActionArea>
      ) : (
        <Skeleton variant="rectangular" width={210} sx={{ height: { xs: 50, md: 140 } }} />
      )}
    </Card>
  );
}
