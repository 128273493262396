import { useEffect, useState } from "react";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { styled, alpha } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import EditImage from "./editImage";
import PublishSettings from "./publishSettings.tsx";
import PreviewPost from "./previewPost";
import AlertDialog from "../../components/dialog";
import SnackBar from "../../components/snackBar";
import CreatePostHeader from "./CreatePostHeader";

import ImageService from "../../services/image.service";
import AuthService from "../../services/auth.service";
import ProductService from "../../services/product.service";
import FacebookService from "../../services/facebook.service";
import ScheduleService from "../../services/schedule.service";
import CreatePostCaption from "./CreatePostCaption";
import CreatePostImageToolbar from "./CreatePostImageToolbar";
import AiMee from "../Aisha/index.tsx";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "rgba(255,255,255,0.8)",
    minWidth: 20,
    maxHeight: 250,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const INSTA_CHARACTER_LIMIT = 2200;

const placeholder = "https://i0.wp.com/tsdheadshop.co.uk/wp-content/uploads/woocommerce-placeholder.png";

export default function CreatePost({ setLoading, user, product, image, cancel, fbConnected, random }) {
  const [postCaption, setPostCaption] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(product ? product : "");
  const [selectedImage, setSelectedImage] = useState({
    url: image ? image : null,
    edited: false,
  });
  const [currentUser, setCurrentUser] = useState(user);

  const [postReady, setPostReady] = useState(false);

  const [fbPages, setFbPages] = useState([]);

  const [selectedHashtags, setSelectedHashtags] = useState([]);

  const [galleryImages, setGalleryImages] = useState([{ original: placeholder }]);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const [publishingPost, setPublishingPost] = useState(false);
  const [postPublished, setPostPublished] = useState(false);

  const [editPending, setEditPending] = useState(false);
  const [editVisible, setEditVisible] = useState(false);

  // snackbar
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");
  const [snackLink, setSnackLink] = useState("");
  const [snackPath, setSnackPath] = useState("");

  // dialog
  const [alertOpen, setAlertOpen] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageSaving, setImageSaving] = useState(false);

  const [draftId, setDraftId] = useState(0);

  useEffect(async () => {
    setLoading(true);

    const draft = localStorage.getItem("selectedDraftId");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    console.log({ draft });
    if (draft && draft != "") {
      setDraftId(draft);
    }

    if (!currentUser) {
      AuthService.getCurrentUser().then((response) => {
        setCurrentUser(response);
      });
    }
  }, []);

  useEffect(() => {
    if (currentUser) getProduct();
  }, [currentUser]);

  useEffect(() => {
    if (!selectedImage) return;
    const isBase64Image = (str) => {
      return /^data:image\/[a-z]+;base64,/.test(str);
    };

    if (isBase64Image(selectedImage)) {
      console.log("It's a base64 image");
      ImageService.uploadImage(selectedImage).then((response) => {
        console.log("new url", response);
        setSelectedImage(response);
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    console.log({ fbConnected });
    if (!fbConnected) fbConnected = localStorage.getItem("fbConnected");
  }, [fbConnected]);

  // LOAD
  const getProduct = () => {
    console.log({ product });
    if (product) {
      setProduct(product);
    } else if (draftId) {
      getDraft(draftId);
    } else if (random) {
      getRandomProduct();
    } else setLoading(false);
  };
  const getRandomProduct = () => {
    setLoading(true);
    setEditVisible(false);
    setEditPending(false);
    ProductService.getRandomProduct(currentUser.id, localStorage.getItem("selectedCategory")).then((response) => {
      console.log(response);
      setProduct(response, true);
    });
  };
  const setProduct = async (product, random) => {
    var _selectedImage = selectedImage;
    if (!image || random) {
      setSelectedImage({ url: product.images[0].src, edited: false });
      _selectedImage = { url: product.images[0].src, edited: false };
    }
    if (selectedProduct == "" || selectedProduct.name != product.name) {
      setSelectedProduct(product);
    }

    const images = product.images;
    var _galleryImages = [];
    var imageurl = _selectedImage.url.split("?")[0];
    _galleryImages.push({
      original: imageurl,
      thumbnail: imageurl,
      edited: false,
    });

    if (images.length > 1) {
      for (const img of images) {
        if (img.src != "") {
          var url = img.src.split("?")[0];
          const image = {
            original: url,
            thumbnail: url,
            edited: false,
          };
          if (_galleryImages[0].original != image.original) {
            _galleryImages.push(image);
          }
        }
      }
      setGalleryImages(_galleryImages);
    } else {
      setGalleryImages(_galleryImages);
    }

    setLoading(false);
  };

  const getDraft = (draftId) => {
    ScheduleService.getPostById(currentUser.id, draftId).then((response) => {
      console.log(response.data);
      const post = response.data;
      setPostCaption(post.caption);
      setSelectedImage({ url: post.image_src, edited: false });
      const image = {
        original: post.image_src,
        thumbnail: post.image_src,
        edited: false,
      };

      var pages = fbPages.slice();
      pages.forEach((element) => {
        if (element.name == post.insta_profile) {
          element.publish = true;
        }
      });

      setFbPages(pages);

      setGalleryImages([image]);
      setLoading(false);
    });
  };

  // IMAGES
  const editImage = (e) => {
    if (editPending) {
      openDialog("Save Changes?", "Do you want to save your changes?", () => setAlertOpen(false));
      return;
    } else if (editVisible) {
      setEditVisible(false);
      return;
    }

    if (selectedImage.edited) {
      setSelectedImage({ url: selectedProduct.images[0].src, edited: false });
      setEditVisible(true);
    } else setEditVisible(true);
  };

  const gallerySlide = (e) => {
    const imageURL = galleryImages[e].original;
    setSelectedImage({ url: imageURL, edited: galleryImages[e].edited });

    setGalleryIndex(e);
  };

  // PUBLISH
  const addHashtagsToCaption = () => {
    return new Promise((resolve) => {
      const _hashtags = selectedHashtags;
      console.log(_hashtags);
      var caption = postCaption;
      caption.concat("\n\n");
      for (const Hashtag of _hashtags) {
        if (Hashtag.hashtag != "") {
          var newCaption = caption.concat(" ", Hashtag.hashtag);
          caption = newCaption;
        }
      }
      resolve(caption);
    });
  };
  const uploadImage = (src) => {
    var filename = "newimage";
    if (selectedProduct) {
      filename = selectedProduct.name.replace(/ /g, "_") + "_" + getDateString();
    }

    return ImageService.uploadImage(src, filename).then((response) => {
      saveEditedImage(response, false);
      return response;
    });
  };
  const publishPost = async (e) => {
    setLoading(true);
    if (postCaption.length > INSTA_CHARACTER_LIMIT) {
      openSnackbar("error", "Caption too long (2200 Character Limit)", true);
    }

    FacebookService.getFBStatus().then(async (response) => {
      console.log(response);
      setPublishingPost(true);
      const caption = await addHashtagsToCaption();
      if (!isValidHttpUrl(selectedImage.url)) {
        const imageURL = await uploadImage(selectedImage.url);
        postToInsta(imageURL, response.authResponse?.accessToken, caption);
      } else {
        postToInsta(selectedImage.url, response.authResponse?.accessToken, caption);
      }
    });
  };
  const postToInsta = async (imageURL, response, caption) => {
    //const facebookPages = await FacebookService.getFacebookPages();
    //var facebookPageId = facebookPages[0].id;
    var postId;
    for (const page of fbPages) {
      if (page.publish) {
        const instagramAccountId = await FacebookService.getInstagramAccountId(page.id, undefined);
        console.log({ instagramAccountId });
        if (!instagramAccountId) {
          setPublishingPost(false);
          setLoading(false);
          openSnackbar("error", "Error getting instagram account id", true);
          return;
        }
        const mediaObjectContainerId = await FacebookService.createMediaObjectContainer(
          instagramAccountId,
          imageURL,
          caption,
          response
        );
        if (!mediaObjectContainerId) {
          openSnackbar("error", "Error creating media object container", true);
          return;
        }
        console.log({ mediaObjectContainerId });
        await FacebookService.publishMediaObjectContainer(instagramAccountId, mediaObjectContainerId).then((result) => {
          if (result.id) {
            postId = result.id;
          } else if (result.error) {
            openSnackbar("error", result.error.message, true);
          }
        });
      }
    }

    setPublishingPost(false);
    setPostPublished(true);
    FacebookService.getInstaPhoto(postId).then((photo) => {
      setLoading(false);
      if (cancel) {
        cancel(photo);
      } else {
        window.location.reload(false);
      }
    });
  };

  function getDateString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    return `${year}${month}${day}`;
  }
  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };
  const openSnackbar = (severity, message, btnHidden, btnMessage, btnPath) => {
    setSnackSeverity(severity);
    setSnackMessage(message);
    setSnackLink(btnMessage);
    setSnackPath(btnPath);
    setSnackOpen(true);
  };
  const openDialog = () => {
    setAlertOpen(true);
  };
  const saveEditedImage = async (img, compress) => {
    if (compress) {
      await ImageService.compressImage(img).then(async (response) => {
        img = response;
        await ImageService.uploadImage(img).then((response) => {
          img = response;
          console.log("uploaded: ", img);
        });
      });
    }
    setEditVisible(false);
    const _galleryImages = galleryImages;
    const editedImage = {
      original: img,
      thumbnail: img,
      loading: "lazy",
      edited: true,
    };
    _galleryImages.splice(0, 0, editedImage);
    console.log({ editedImage });
    setSelectedImage({ url: editedImage.original, edited: true });
    setImageSaving(false);
    setEditPending(false);
    setGalleryIndex(0);
    setLoading(false);
  };
  const alertNo = () => {
    setAlertOpen(false);
    setEditVisible(false);
    setEditPending(false);
  };
  const alertYes = () => {
    setImageSaving(true);
    setAlertOpen(false);
  };
  const queuePost = async (e) => {
    try {
      var hashtags = [];

      selectedHashtags.forEach((element) => {
        hashtags.push(element.hashtag);
      });

      var pages = fbPages.slice();
      var pageId = 0;
      var profile = "";
      var instaId = 0;
      pages.forEach((element) => {
        if (element.publish == true) {
          profile = element.name;
          pageId = element.id;
        }
      });

      const token = await FacebookService.getFBStatus();

      if (!token) {
        openSnackbar("error", "No FB Token", true);
        return;
      }
      instaId = await FacebookService.getInstagramAccountId(pageId, token.authResponse.accessToken);
      if (!instaId) {
        openSnackbar("error", "No insta id", true);
        return;
      }
      let caption = postCaption;
      if (selectedHashtags) {
        caption = await addHashtagsToCaption();
      }

      const post = {
        draft_id: draftId,
        user_id: currentUser.id,
        insta_id: instaId,
        insta_profile: profile,
        product_id: selectedProduct.id,
        image_src: selectedImage.url,
        product_name: selectedProduct.name,
        caption: caption,
        hashtags: hashtags.join(", "),
        publish_date: null,
        publish_time: null,
      };
      ScheduleService.queuePost(post).then((response) => {
        console.log(response);
        openSnackbar("success", "Post queued succesfully", true);
      });
    } catch (error) {
      console.log(error);
      openSnackbar("error", error.message);
    }

    // get post id, set it in storage

    //localStorage.setItem("currentPost", JSON.stringify(post))
  };

  /*
  const createBackground = () => {
    setLoading(true);
    console.log(selectedImage);
    console.log(product);
    ImageService.createProductBackground(selectedImage.url, product.name + ' ' + product.description)
      .then((background) => {
        console.log(background);
        const _galleryImages = galleryImages;
        const images = background.image;
        for (const image of images) {
          console.log(image);
          const newImage = {
            original: image,
            thumbnail: image,
            loading: "lazy",
            edited: true,
          };
          _galleryImages.splice(0, 0, newImage);
        }

        setSelectedImage({ url: images[0], edited: true });
        setGalleryIndex(0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  */
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Container>
        <AlertDialog
          alertOpen={alertOpen}
          title={"Save Changes?"}
          message={"Do you want to save your changes?"}
          onNo={alertNo}
          onYes={alertYes}
          cancel={() => setAlertOpen(false)}
        />
        <Box>
          <CreatePostHeader selectedProduct={selectedProduct} getRandomProduct={getRandomProduct} />
          <Grid sx={{ my: 3 }} container spacing={2}>
            <Modal
              open={previewOpen}
              onClose={() => setPreviewOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <PreviewPost
                  caption={postCaption}
                  hashtags={selectedHashtags}
                  onClose={() => setPreviewOpen(false)}
                  selectedImage={selectedImage.url}
                />
              </Box>
            </Modal>
            <Grid item md={editVisible ? 12 : 5} xs={12}>
              <CreatePostImageToolbar
                editImage={editImage}
                editVisible={editVisible}
                uploadImage={uploadImage}
              />
              <EditImage
                setLoading={setLoading}
                imageSaving={imageSaving}
                selectedImage={selectedImage.url}
                editing={editVisible}
                setEditPending={() => setEditPending(true)}
                imageSaved={saveEditedImage}
                currentUser={currentUser}
              />
              <Box hidden={editVisible}>
                <Box sx={{ minHeight: { md: "400px", xs: "200px" } }}>
                  <ImageGallery
                    startIndex={galleryIndex}
                    onImageLoad={() => setPostReady(true)}
                    items={galleryImages}
                    showPlayButton={false}
                    onSlide={gallerySlide}
                    showThumbnails={galleryImages.length > 1 ? true : false}
                  />
                </Box>
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <PublishSettings
                    previewPost={() => setPreviewOpen(true)}
                    disabled={publishingPost}
                    cancel={cancel}
                    fbPages={fbPages}
                    setFbPages={setFbPages}
                    postPublished={postPublished}
                    publishingPost={publishingPost}
                    publishPost={publishPost}
                    queuePost={queuePost}
                    fbConnected={fbConnected}
                  />
                </Box>
              </Box>
            </Grid>

            <CreatePostCaption
              hashtags={hashtags}
              setHashtags={setHashtags}
              selectedHashtags={selectedHashtags}
              setSelectedHashtags={setSelectedHashtags}
              currentUser={currentUser}
              product={selectedProduct}
              publishingPost={publishingPost}
              editVisible={editVisible}
              postCaption={postCaption}
              setPostCaption={setPostCaption}
            />
            <Box id="mobile-publish-settings" sx={{ display: { xs: "block", md: "none" }, width: "100%" }}>
              <PublishSettings
                previewPost={() => setPreviewOpen(true)}
                disabled={publishingPost}
                cancel={cancel}
                fbPages={fbPages}
                setFbPages={setFbPages}
                postPublished={postPublished}
                publishingPost={publishingPost}
                publishPost={publishPost}
                queuePost={queuePost}
                fbConnected={fbConnected}
              />
            </Box>
          </Grid>
        </Box>
        <SnackBar
          snackOpen={snackOpen}
          message={snackMessage}
          severity={snackSeverity}
          btnMessage={snackLink}
          btnPath={snackPath}
          onClose={() => setSnackOpen(false)}
        />
      </Container>
    </LocalizationProvider>
  );
}
