import {
  Box,
  TextField,
  Paper,
  IconButton,
  InputBase,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  ListItemButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import OpenAIService from "../../../services/openai.service";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";

type Message = {
  text: string;
  type: "question" | "answer";
  timestamp: Date;
};

export default function Aisha() {
  const [prompt, setPrompt] = useState("");
  const [chat, setChat] = useState("");
  const [tokens, setTokens] = useState(0);
  const [cost, setCost] = useState(0);

  const [messages, setMessages] = useState<Message[]>([]);

  const [loading, setLoading] = useState(false);

  /*
  {
  text: string, // the text of the message
  type: 'question' | 'answer', // the type of the message (question or answer)
  timestamp: Date // the timestamp of the message
}
*/

  const promptChangeHandler = (e) => {
    setPrompt(e.target.value);
  };

  const submitPrompt = () => {
    setChat("hmm....");
    // test
    const message: Message = {
      text: prompt,
      type: "question",
      timestamp: new Date(),
    };

    setMessages((messages) => [...messages, message]);
    getResponse();
  };

  const getResponse = () => {
    setLoading(true);
    OpenAIService.completeChatFromPrompt(prompt).then((response) => {
      var result = response.data.result;
      var tokens = response.data.tokens;
      //result = result.replace(/(\r\n|\n|\r)/gm, "");

      const answer: Message = {
        text: result,
        type: "answer",
        timestamp: new Date(),
      };

      setMessages((messages) => [...messages, answer]);
      setTokens((prevTokens) => prevTokens + tokens);
      setPrompt('')
      setLoading(false);
    });
  };

  useEffect(() => {
    if (tokens) {
      const cost = (tokens / 1000) * 0.002;
      setCost(cost);
    }
  }, [tokens]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    chatSection: {
      width: "100%",
      height: "300px"
    },
    headBG: {
      backgroundColor: "#e0e0e0",
    },
    borderRight500: {
      borderRight: "1px solid #e0e0e0",
    },
    messageArea: {
      overflowY: "auto",
      height: "200px",
      width: "100%"
    },
  });

  const classes = useStyles();

  return (
    <Box>
      <b>
        Tokens this session: {tokens} | Cost: {cost}
      </b>

      <div>
        <Grid container component={Paper} className={classes.chatSection}>
          <Grid item xs={12}>
            <List className={classes.messageArea}>
              {messages.map((message, index) => (
                <ListItem key={index}>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText align={message.type === "question" ? "right" : "left"} primary={message.text} />
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        align={message.type === "question" ? "right" : "left"}
                        secondary={message.timestamp.toLocaleTimeString()}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
              {loading && (
                <ListItem key="...">
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText align="left" primary={"..."} />
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </List>
            <Divider />
            <Grid container style={{ padding: "20px" }}>
              <Grid item xs={11}>
                <TextField
                  id="prompt"
                  label="Send a message..."
                  fullWidth
                  onChange={promptChangeHandler}
                  value={prompt}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
              <Grid xs={1} align="right">
                <Fab color="primary" aria-label="add" onClick={submitPrompt}>
                  <ArrowForwardIcon />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
