import React, { Component, useState, useEffect } from "react";

import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Checkbox, Typography } from "@mui/material";
import IntegrationTabs from "../Integration/index.tsx";
import { CurrentUser, BaseProps } from "../../types/global";

const querystring = require("querystring");

const endpoint = "/wc-auth/v1/authorize";

type SettingsProps = {} & BaseProps;

export const Settings = ({ setLoading }: SettingsProps) => {
  const [storeStatus, setStoreStatus] = useState("disconnected");
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [userReady, setUserReady] = useState(false);
  const [instaProfile, setInstaProfile] = useState();
  const [storeURLs, setStoreURLs] = useState<string[]>([]);
  const [selectedStoreURL, setSelectedStoreURL] = useState("");
  const [dbCount, setDbCount] = useState(0);
  useEffect(() => {
    // GET CURRENT USER

    // CHECK IF STORE CONNECTED

    // get latest user settings from db *redo refresh call*
    AuthService.getCurrentUser().then((userResponse) => {
      var currentUser = userResponse;
      console.log(currentUser);
      if (currentUser.connected) {
        connectStore(currentUser);
      } else {
        setCurrentUser(currentUser);
        setUserReady(true);

        setLoading(false);
      }
    });
  }, []);

  const connectUser = (user: CurrentUser) => {
    setStoreStatus("connected");
    UserService.getUserStores(user.id).then((response) => {
      let stores: string[] = [];
      response.forEach((element) => {
        stores.push(element.store_url);
      });

      setStoreURLs(stores);

      setCurrentUser(user);
      setUserReady(true);
      setSelectedStoreURL(user.store_url);

      setLoading(false);
    });
  };

  const pageSelected = (e) => {
    localStorage.setItem("selectedPage", e.target.value);
    setInstaProfile(e.target.value);
  };

  // SYNCING
  const connectStore = (user: CurrentUser) => {
    console.log("connecting");
    UserService.connectUserStore(user.id, user.store_url).then(
      (response) => {
        console.log(response);
        if (response) {
          user.product_count = response.storeCount;
          setDbCount(response.dbCount);

          connectUser(user);
        } else {
          user.store_url = "";
          setCurrentUser(user);
          setUserReady(true);
          //setError(true);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const syncStore = () => {
    console.log("connecting");
    if (!currentUser) return;
    let user = currentUser;
    UserService.syncUserStore(user.id, user.store_url).then(
      (response) => {
        console.log(response);
        if (response) {
          setDbCount(response);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const disconnectStore = () => {
    if (!currentUser) return;
    UserService.disconnectStore(currentUser.id, selectedStoreURL);
  };
  const addStore = () => {
    if (!currentUser) return;
    currentUser.store_url = "";
    setSelectedStoreURL("");
    setStoreStatus("disconnected");
  };
  const storeChanged = (e) => {
    const user = currentUser;
    if (!user) return;
    user.store_url = e.target.value;

    UserService.setStoreURL(user.username, user.store_url).then(() => {
      connectStore(user);
    });
    setCurrentUser(user);
    window.location.reload();
  };

  const saveSettings = (e) => {
    const user = currentUser;
    if (!user) return;
    const minStock = user.min_stock;
    UserService.setMinStock(user.id, minStock);
  };
  const handleStockChange = (e) => {
    //this.setState({ minStock: e.target.value });
  };

  const [manageStock, setManageStock] = useState(false);

  useEffect(() => {
    console.log("setting manage stock: ", manageStock);
    localStorage.setItem("manageStock", manageStock.toString());
  }, [manageStock]);
  return (
    <Box>
      {userReady && (
        <Box>
          <Typography variant="h3">{storeStatus === "connected" ? "Settings" : "Welcome"}</Typography>
          {storeStatus === "connected" ? (
            <Box>
              <hr></hr>
              <Typography variant="h4" sx={{ my: 2 }}>
                WooCommerce Connection
              </Typography>
              <div className="store-url">
                <FormControl fullWidth>
                  <InputLabel id="url-label">WooCommerce Store</InputLabel>
                  <Select
                    labelId="url-label"
                    label="WooCommerce Store"
                    defaultValue={currentUser?.store_url}
                    onChange={storeChanged}
                  >
                    {storeURLs?.map((url, index) => {
                      return (
                        <MenuItem value={url} key={index}>
                          {url}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {storeStatus === "connected" && currentUser && (
                <Box>
                  <Box>
                    <Stack direction="row">
                      <Typography variant="h5" sx={{ my: 2 }}>
                        Store Status:
                      </Typography>
                      <Typography variant="h5" color="green" sx={{ m: 2 }}>
                        {storeStatus}
                      </Typography>

                      <div id="storeCount">
                        <Typography variant="h6" sx={{ m: 2 }}>
                          Products: {currentUser?.product_count}
                        </Typography>
                      </div>
                    </Stack>
                    <Stack direction="row">
                      <Typography variant="h5" sx={{ my: 2 }}>
                        Database Status:
                      </Typography>
                      <Typography
                        variant="h5"
                        color={dbCount < currentUser?.product_count ? "red" : "green"}
                        sx={{ m: 2 }}
                      >
                        {dbCount < currentUser?.product_count ? "not synced" : "synced"}
                      </Typography>
                      <div id="storeCount">
                        <Typography variant="h6" sx={{ m: 2 }}>
                          Products: {dbCount}
                        </Typography>
                      </div>
                    </Stack>
                    <Button color="success" onClick={syncStore} variant="contained" sx={{ mx: 2 }}>
                      Sync Store
                    </Button>
                    <Stack direction="row" sx={{ my: 2 }}>
                      <Button color="error" onClick={disconnectStore} variant="contained">
                        Disconnect Store
                      </Button>
                      <Button color="success" onClick={addStore} variant="contained" sx={{ mx: 2 }}>
                        Add Store
                      </Button>
                    </Stack>
                    <hr></hr>
                  </Box>
                  <Box>
                    <Typography variant="h6" sx={{ my: 2 }}>
                      Random Product Settings
                    </Typography>
                    <TextField
                      id="min-stock"
                      label="Minimum Stock Quantity"
                      type="number"
                      value={currentUser?.min_stock}
                      onChange={handleStockChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Typography variant="h6" sx={{ my: 2 }}>
                      Manage Stock
                    </Typography>
                    <Checkbox checked={manageStock} onChange={() => setManageStock(!manageStock)} />
                    <Button color="success" onClick={saveSettings} variant="contained" sx={{ mx: 2 }}>
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <IntegrationTabs currentUser={currentUser} setLoading={setLoading} />
          )}
        </Box>
      )}
    </Box>
  );
};
