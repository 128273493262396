import React, { Component } from "react";
import { withRouter } from '../../utils/with-router.js'
import AuthService from "../../services/auth.service";
import FacebookService from '../../services/facebook.service'
import InstagramFeed from './instagramFeed';
import { Box, Button, Container, FormControl, InputLabel, Select, MenuItem, Stack, Typography } from "@mui/material";
import UserService from "../../services/user.service";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      date: new Date().toDateString(),
      productCount: 0,
      imageCount: 0,
      instaToken: "",
      instaPhotos: [],
      mobile: this.props.mobile,
      facebookPages: [],
      instaProfile: "",
      fbConnected: false,
      scheduleCount: 0,
      draftCount: 0,
      nextDate: ""
    };
  }
  async componentDidMount() {
    let _currentUser = "";
    if(this.props.user) {
      _currentUser = this.props.user;
    }
    else {
      await AuthService.getCurrentUser()
      .then(async (currentUser) => {     
        if(currentUser) {    
          _currentUser = currentUser                     
        }  
      })  
    }     
    this.setState({ currentUser: _currentUser, userReady: true });   
    if(_currentUser.connected) this.setState({ connected: true })        
    this.setState({ productCount: _currentUser.product_count })   

    this.getInstaPhotos();
    
    this.props.setLoading(false)
  }
  componentDidUpdate() {
    if(this.props.fbConnected && this.props.selectedFbPage != this.state.instaProfile) this.getInstaPhotos()
  }

  getInstaPhotos = async () => {
    if(!this.props.fbConnected) return;
    this.setState({ instaPhotos: [], instaProfile: this.props.selectedFbPage})
    var instagramAccountId = await FacebookService.getInstagramAccountId(this.props.selectedFbPage);    

    const photos = await FacebookService.getInstaPosts(instagramAccountId)
    this.setState({ instaPhotos: photos })
  }
  createRandomPost = () => {
    localStorage.setItem("postCaption", "");
    localStorage.setItem("selectedProductId", "");
    localStorage.setItem("selectedProduct", "")
    localStorage.setItem("selectedCategory", 0)
    this.props.router.navigate("/create-post");
  }    
  render() {    
    return (
      <Container>        
        <Box id="home-header">   
        {this.state.currentUser ? (
          <div className="container">            
            <Typography variant="h3" sx={{mb: '30px'}}>Hey, {this.state.currentUser.username}! It's {this.state.date} </Typography> 
            {this.state.productCount ? (
              <div className="welcome-text">                                  
              <p>Your store <b style={{ margin: '20px 0' }}><a href={this.state.currentUser.store_url} target="_blank">({this.state.currentUser.store_url})</a></b> is <b style={{ color: "green" }}>connected</b> with {this.state.productCount} products.</p>
              </div>
            ) : (            
              <div className="container">                
                <Typography variant="h4" color="error">Your store is Not Connected</Typography> 
                <Typography variant="h5">Head to the Settings Page</Typography>
              </div>   
            )} 
          </div>
        ) : (
            <div className="welcome-text">
                <Typography variant="h3">Welcome to The Product Poster</Typography>
              </div> 
        )}           
        </Box>

        <Box id="home-content" sx={{ mt: '50px'}}>
        {this.state.currentUser ? (
          <div>
              <Box hidden={!this.state.connected}>        
                <Typography variant="h4" sx={{ mb: '30px'}}>Shortcuts</Typography>
               <Button variant="contained" onClick={this.createRandomPost}>Create Random Post</Button>       
               </Box>               
               <Stack sx={{ flexDirection: { xs: "column", md: "row"}, my: '50px' }}>
               <Typography variant="h4" style={{ marginRight: '10px', marginBottom: '10px'}}>Instagram Feed</Typography>                               
                </Stack> 
                <Box hidden={this.props.fbConnected}>                
                <Typography variant="h5" color="error" style={{ marginRight: '10px', marginBottom: '10px'}}>You must be logged in to Facebook to view your Instagram Feed</Typography>  
              </Box>
               <Box hidden={!this.props.fbConnected}>    
               {this.state.instaPhotos.length ? (
                <InstagramFeed images={this.state.instaPhotos}/> 
               ) : ( 
                <Typography variant="h5" color="error" style={{ marginRight: '10px', marginBottom: '10px'}}>No Posts Found</Typography> 
               )}            
              </Box>
          </div>
        ) : (
            <div className="container">
                <b>The quickest and easiest way to create high quality social posts from any product on your website!</b>
                <b>Create Posts for Products from your Pocket</b>
                <h4 className="mt-3">Features:</h4>
                <ul>
                  <li>Create posts from any product on your site</li>
                  <li>Caption, hashtags and emojis automatically generated based on product info</li>                  
                  <li>AI-Powered caption generating</li>
                  <li>Hashtag suggestions</li>
                  <li>Emoji suggestions</li>
                  <li>Remove white background from photo</li>
                  <li>Replace the background with either a solid colour or any photo</li>
                  <li>Find backgrounds on the fly</li>  
                </ul>    

                <b>Login or Create an Account to get posting</b>
            </div> 
        )}   
        </Box>    
      </Container>    
    )};
}

export default withRouter(Home);