import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import productService from "../../../services/product.service";
import openaiService from "../../../services/openai.service";

const CategoryMapper = (props) => {
  const [storeCategories, setStoreCategories] = useState([]);
  const [supplierCategories, setSupplierCategories] = useState([]);
  const [mappedCategories, setMappedCategories] = useState([]);
  const [selectedSupplierCategory, setSelectedSupplierCategory] = useState();
  const [selectedStoreCategories, setSelectedStoreCategories] = useState([]);

  useEffect(() => {
    if (props.currentUser.id) {
      if (!storeCategories.length) getStoreCategories();
      if (!supplierCategories.length) getSupplierCategories();
    }
  }, [props.currentUser]);

  const handleMapping = (supplierCategoryId, storeCategoryIds) => {
    // Logic to establish mapping between supplierCategoryId and storeCategoryIds
    // This might involve API calls to save the mapping on the backend
    // Update mappedCategories state or perform any necessary actions
    const ids = storeCategoryIds.map((category) => category.id);
    productService.mapCategories(supplierCategoryId, ids).then((response) => {
      console.log({ response });
    });
  };

  const getStoreCategories = () => {
    productService.getAllCategories(props.currentUser.id).then((response) => {
      response = response.sort((a, b) => b.count - a.count);
      setStoreCategories(response);
    });
  };

  const getSupplierCategories = () => {
    productService.getSupplierCategories(props.currentUser.id).then((response) => {
      const categories = response.cats;
      setSupplierCategories(categories);
    });
  };

  useEffect(() => {
    if (selectedSupplierCategory) {
      productService.getMappedCategories(selectedSupplierCategory).then((response) => {
        const categories = response.data.data;
        // Map the fetched category IDs to the corresponding store category objects
        const mappedCategories = categories.map((category) => {
          return storeCategories.find((storeCategory) => storeCategory.id === category.storeCategoryId);
        });

        // Set the mapped store categories (with IDs and names) to selectedStoreCategories
        setSelectedStoreCategories(mappedCategories);
      });
    }
  }, [selectedSupplierCategory]);

  const getCategorySuggestions = () => {
    if (storeCategories) {
      const supplierCategory = supplierCategories.find(
        (storeCategory) => storeCategory.id === selectedSupplierCategory
      );
      const storeCats = storeCategories.map((category) => category.name);
      openaiService.getCategorySuggestion(supplierCategory.name, storeCats).then((response) => {
        console.log({ response });
      });
    }
  };

  return (
    <div>
      <Typography variant="h5">Category Mapper</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Supplier Categories</Typography>
          <FormControl fullWidth>
            <InputLabel>Select Supplier Category</InputLabel>
            <Select value={selectedSupplierCategory} onChange={(e) => setSelectedSupplierCategory(e.target.value)}>
              {supplierCategories.map((supplierCategory) => (
                <MenuItem key={supplierCategory.id} value={supplierCategory.id}>
                  {supplierCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Store Categories</Typography>
          <Autocomplete
            multiple
            options={storeCategories}
            getOptionLabel={(option) => option.name}
            value={selectedStoreCategories}
            open={true} // Keep the Autocomplete open
            onClose={(event, reason) => {
              event.preventDefault();
              if (reason === "toggleInput") {
                // Close Autocomplete only when the input is toggled
                return;
              }
              // Handle other closing reasons if needed
            }}
            onChange={(event, newValue) => setSelectedStoreCategories(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Store Categories" placeholder="Select Categories..." />
            )}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleMapping(selectedSupplierCategory, selectedStoreCategories)}
      >
        Map Categories
      </Button>
    </div>
  );
};

export default CategoryMapper;
