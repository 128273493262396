import React from "react";
import "../styles/footer-style.css";
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import ButtonGroup from '@mui/material/ButtonGroup'

export const Footer = () => {
  return (
    <div className="footer">
      <div>
        <div className="footer-content">
              <Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography>Copyright © The Product Poster 2023</Typography>
                <ButtonGroup size="small" aria-label="small button group">
                  <IconButton>
                    <FacebookIcon/>
                  </IconButton>
                  <IconButton>
                    <TwitterIcon/>
                  </IconButton>
                  <IconButton>
                    <InstagramIcon/>
                  </IconButton>
                </ButtonGroup>
              </Stack>
            </Box>
        </div>
      </div>
    </div>
  );
};
