import React, { Component, useEffect, useState } from "react";

import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import ProductService from "../../../services/product.service";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { BaseProps } from "../../Settings/Settings";

const querystring = require("querystring");

export const ApiSetup = ({ currentUser }: BaseProps) => {
  const [apiURL, setApiURL] = useState("");
  const [apiKey, setApiKey] = useState("");

  const fetchProductsClick = (e) => {
    if (!currentUser) return;
    if (apiURL) {
      const _apiURL = "https://" + apiURL;

      UserService.connectApi(currentUser.id, _apiURL, apiKey);
    }
  };

  const handleApiURLChange = (e) => {
    const { value } = e.target;

    setApiURL(value);
  };

  const handleApiKeyChange = (e) => {
    const { value } = e.target;

    setApiKey(value);
  };

  return (
    <Box>
      {currentUser?.store_status != "Connected" && (
        <Box>
          <div>
            <Typography variant="h4" sx={{ my: 2 }}>
              Please enter your API endpoint URL and API Key
            </Typography>
            <TextField
              fullWidth
              label="API URL"
              placeholder="Enter your API URL (https://server.mystore.com/api/products?)"
              onChange={handleApiURLChange}
              value={`${apiURL}`}
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
            />
            <TextField
              label="API Key"
              placeholder="Enter your API Key..."
              onChange={handleApiKeyChange}
              value={apiKey}
            />
          </div>

          <Button variant="contained" color="success" sx={{ my: 2 }} onClick={fetchProductsClick}>
            Fetch Products
          </Button>
        </Box>
      )}
    </Box>
  );
};
