import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function CaptionToolbar(props) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" sx={{ mb: 1 }}>
        <Typography variant="h5" fontWeight={500} width={120}>
          Caption
        </Typography>
        <ButtonGroup>
          <Tooltip title="Copy Caption & Hashtags">
            <Button size="small" style={{ backgroundColor: "grey" }} variant="contained" onClick={props.copyCaption}>
              <ContentCopyIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Reset Caption">
            <Button size="small" style={{ backgroundColor: "grey" }} variant="contained" onClick={props.resetCaption}>
              <RestartAltIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Suggested Emojis">
            <Button
              id="demo-customized-button"
              aria-controls={props.emojiOpen ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={props.emojiOpen ? "true" : undefined}
              variant="contained"
              size="small"
              style={{ backgroundColor: "#ffcd38" }}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={props.handleEmojiClick}
            >
              <AddReactionIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            margin: "5px 10px",
            display: { md: "block", xs: "none" },
          }}
        >
          <i>
            Use <kbd>⊞ Win</kbd> Key + <kbd>.</kbd> for more Emojis
          </i>
        </Typography>
        <IconButton
          hidden={props.postCaption.length > 600 ? false : true}
          size="small"
          onClick={() => props.setMinCaption(!props.minCaption)}
        >
          {props.minCaption ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </IconButton>
      </Stack>
    </Stack>
  );
}
