import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductItem from "./ProductItem";
import ProductService from "../../services/product.service";

export default function ProductGrid(props) {
  const { products, setProducts } = props;
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(100);

  useEffect(() => {
    if (!props.currentCategory) return;
    setProducts([]);
    setLoading(true);
  }, [props.currentCategory]);

  useEffect(() => {
    console.log({ products })
    if (!products.length) getProducts(props.currentCategory.id);
  }, [products, props.currentCategory]);

  useEffect(async () => {
    const currentProducts = [...products];
    if (!currentProducts.length) return;
    let newProducts = await getProducts(props.currentCategory.id);
    newProducts = currentProducts.concat(newProducts);
    setProducts(newProducts);
  }, [pageNumber]);

  const loadingElements = (count) => {
    var elements = [];
    for (var i = 0; i < count; i++) {
      elements.push(i);
    }
    return elements;
  };

  // GET PRODUCTS
  const getProducts = async (categoryId) => {
    console.log(loading);
    if (!categoryId) categoryId = 0;

    return ProductService.getProductsByCategory(props.currentUser.id, perPage, pageNumber, categoryId)
      .then((response) => {
        if (response) {
          const productCount = response.TotalCount;
          const products = response.Products;
          console.log({ productCount });
          props.setProducts(products, productCount);

          if (products.length < productCount) {
            setLoadMore(true);
          } else setLoadMore(false);

          setLoading(false);
          //this.getImages(products);

          return products;
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadMoreProducts = async (e) => {
    console.log("loading more...");
    var productCount = products.length;
    if (!productCount) return;
    if (productCount < props.totalCount) {
      var page = pageNumber;
      page++;

      setLoading(true);
      setPageNumber(page);
    }
  };
  const resetProducts = () => {
    setCurrentCategory({ name: "All Products" });
  };

  return (
    <Box hidden={props.hidden}>
      <InfiniteScroll
        className="product-item-container"
        dataLength={products?.length}
        hasMore={loadMore}
        pageStart={0}
        loader={<h4 className="my-2">Loading Products...</h4>}
        next={loadMoreProducts}
      >
        <Box id="products">
          {products?.map((product, index) => {
            return <ProductItem key={index} {...product} onClick={props.createPost} createPost={props.createPost} />;
          })}
        </Box>
        <Box id="loading-products" hidden={!loading}>
          {loadingElements(100).map((product, index) => {
            return <ProductItem key={index} {...product} />;
          })}
        </Box>
      </InfiniteScroll>
    </Box>
  );
}
