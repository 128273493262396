import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Category from "../category/index.tsx";
import productService from "../../../services/product.service.js";
import EditCategoryPopup from "../edit-category/index.tsx";

const loadingElements = (count) => {
  var elements = [];
  for (var i = 0; i < count; i++) {
    elements.push(i);
  }
  return elements;
};

export default function Categories(props) {
  const [loading, setLoading] = useState(true);
  const [categoryTree, setCategoryTree] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState(loadingElements(7));
  const [currentCategory, setCurrentCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState();

  useEffect(async () => {
    if (!props.currentUser) return;
    getAllCategories();
  }, [props.currentUser]);

  // GET CATEGORIES
  const getAllCategories = () => {
    productService.getAllCategories(props.currentUser.id).then((response) => {
      response = response.sort((a, b) => b.count - a.count);

      const cats = unflatten(response);
      setCategoryTree(cats);
      setAllCategories(response);

      setCurrentCategories(cats);
      setCurrentCategory({ name: "All Products", id: 0 });
      setLoading(false);
    });
  };
  const unflatten = (data) => {
    const tree = {};
    data.forEach((category) => {
      const { id, parent } = category;
      category.name = category.name.replace(/&amp;/g, "&");

      if (!tree[id]) {
        tree[id] = { ...category, children: [], open: false };
      } else {
        tree[id] = { ...category, children: tree[id].children, open: false };
      }

      if (parent) {
        tree[parent] = tree[parent] || { children: [] };
        tree[parent].children.push(tree[id]);
      }
    });

    const rootCategories = Object.values(tree).filter((node) => !node.parent);
    return rootCategories;
  };
  const setParentCategories = async () => {
    setCurrentCategories(categoryTree);
    setCurrentCategory({ name: "All Products", id: 0 });

    setLoading(false);

    // reset currentImages / products
    // setloading
  };
  const getParentCategory = () => {
    var parent = allCategories.find((obj) => {
      return obj.id === currentCategory.parent;
    });
    if (!parent) {
      setParentCategories();
      return;
    }

    findGrandparent(parent.id, categoryTree);
  };
  const findGrandparent = (id, array) => {
    for (const cat of array) {
      if (cat.id === id) folderSelected(cat);
      else if (cat.children) findGrandparent(id, cat.children);
    }
  };

  // CATEGORY SELECTED
  const folderSelected = async (e) => {
    var categoryId = e.id;
    var selectedCategory = e;
    setCurrentCategory(selectedCategory);
    setCurrentCategories(selectedCategory.children);
  };

  const editCategory = async (category) => {
    setEditingCategory(category);
  };

  const closePopup = () => {
    setEditingCategory(undefined);
  };

  return (
    <Box id="categories">
      {editingCategory ? (
        <EditCategoryPopup currentUser={props.currentUser} closePopup={closePopup} selectedCategory={editingCategory} />
      ) : (
        <>
          <Stack sx={{ my: "20px", flexDirection: { md: "row", xs: "column" } }}>
            <ButtonGroup disabled={loading} sx={{ mb: 2 }}>
              <Button onClick={setParentCategories}>Home</Button>
              <Button onClick={getParentCategory}>Back</Button>
            </ButtonGroup>
            <Typography variant="h3" sx={{ mx: 3 }} color={props.connected ? "white" : "error"}>
              {props.connected ? currentCategory.name : "Store Not Connected"}
            </Typography>
          </Stack>
          <Box id="product-categories" style={{ display: "flex", flexWrap: "wrap" }} className="my-3">
            {currentCategories?.map((category, index) => {
              return (
                <Category key={index} {...category} loading={loading} click={folderSelected} edit={editCategory} />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
}
