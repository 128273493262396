import React, { useState } from "react";
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from "@mui/material/Typography";
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close';

function PreviewPost({ hashtags, caption, selectedImage, onClose }) {
    const [title, setTitle] = useState("TheProductPoster")

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', md: '400px' },
        maxHeight: '90%',
        bgcolor: '#040404',
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
        cursor: 'default',
        color: 'white'
    };

    return (
        <div>
            <Card sx={style}>
                <Toolbar>
                    <Avatar></Avatar>
                    <Typography className="mx-3" style={{ color: 'white' }}>{title}</Typography>
                    <IconButton style={{ color: 'white', marginBottom: '30px', position: 'fixed', right: '0px' }} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <div className="mb-3">
                    <img style={{ border: '1px solid black' }} src={selectedImage} width='100%' />
                </div>

                <div className="mb-1" style={{ fontSize: 14, overflowY: 'auto' }}>
                    <TextField value={caption} fullWidth multiline rows={6} inputProps={{ style: { color: "white" } }} ></TextField>
                </div>

                <div style={{ overflowWrap: 'break-word', color: 'cornflowerblue', fontSize: 14, maxHeight: 150, overflowY: 'auto' }}>
                    {hashtags?.map(hashtag => {
                        return ' ' + hashtag.hashtag
                    })
                    }
                </div>
            </Card>
        </div>
    );
}

export default PreviewPost;
