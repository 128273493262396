import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { lighten, darken } from "@mui/system";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Chip from "@mui/material/Chip";
import ProductService from "../../../../services/product.service";
import { Collapse, IconButton, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import productService from "../../../../services/product.service";

const INSTA_HASHTAG_LIMIT = 30;

const filter = createFilterOptions();

const GroupHeader = styled("div")(({ theme }) => ({
  zIndex: 999,
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function AddProductCategories({
  currentUser,
  setSelectedCategories,
  selectedCategories,
  selectedProduct,
}) {
  const [categories, setCategories] = useState([]);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [categoryTree, setCategoryTree] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  const hashtagSelected = (selectedTags) => {
    const newTag = selectedTags[selectedTags.length - 1];
    setSelectedCategories(selectedTags);
  };

  const handleCategorySelected = (selectedCategory) => {
    var selected = [...selectedCategories];
    selected.push(selectedCategory);
    setSelectedCategories(selected);
  };

  const getCategories = () => {
    ProductService.getAllCategories(currentUser.id).then((response) => {
      response = response.sort((a, b) => b.count - a.count);

      const cats = unflatten(response);
      setCategoryTree(cats);
      setAllCategories(response);
      setCategories(response);
    });
  };
  const unflatten = (data) => {
    const tree = {};
    data.forEach((category) => {
      const { id, parent } = category;
      category.name = category.name.replace(/&amp;/g, "&");

      if (!tree[id]) {
        tree[id] = { ...category, children: [], open: false };
      } else {
        tree[id] = { ...category, children: tree[id].children, open: false };
      }

      if (parent) {
        tree[parent] = tree[parent] || { children: [] };
        tree[parent].children.push(tree[id]);
      }
    });

    const rootCategories = Object.values(tree).filter((node) => !node.parent);
    return rootCategories;
  };

  const addCategory = async (category) => {
    var _categories = [...categories];
    var newCategory = { type: "Added", category };
    _categories.splice(0, 0, newCategory);
    var _selected = [...selectedCategories];
    _selected.push(newCategory);
    setCategories(_categories);
    setSelectedCategories(_selected);
  };

  useEffect(() => {
    if (!allCategories.length) getCategories();
  }, []);

  const openGroup = (group) => {
    const updatedTree = [...categoryTree];
    const toggleOpen = (categories) => {
      return categories.map((category) => {
        if (category.id === group.id) {
          return { ...category, open: !category.open };
        } else if (category.children && category.children.length > 0) {
          return { ...category, children: toggleOpen(category.children) };
        }
        return category;
      });
    };

    const newTree = toggleOpen(updatedTree);
    setCategoryTree(newTree);
  };

  useEffect(() => {
    if (selectedProduct && allCategories.length) getMappedCategories();
  }, [selectedProduct.id, allCategories]);

  const getMappedCategories = () => {
    const categories = selectedProduct.categories;
    const ids = categories.map((category) => category.id);
    let mappedCats = new Set();

    Promise.all(
      ids.map((id) =>
        productService.getMappedCategories(id).then((response) => {
          const categories = response.data.data;
          const mappedCategories = categories.map((category) => {
            return allCategories.find((storeCategory) => storeCategory.id === category.storeCategoryId);
          });

          mappedCategories.forEach((mappedCategory) => {
            mappedCats.add(mappedCategory);
          });
        })
      )
    ).then(() => {
      if (selectedProduct.stock > 50) {
        const whatsNewCategory = allCategories.find((category) => category.name === "What's New?");
        if (whatsNewCategory) {
          mappedCats.add(whatsNewCategory); // Add the "What's new?" category to the set
        }
      }

      setSelectedCategories([...mappedCats]);
    });
  };

  return (
    <FormGroup className="mb-2">
      <Autocomplete
        className="mt-2"
        style={{ maxWidth: "100%" }}
        id="multiple-limit-tags"
        value={selectedCategories}
        options={categoryTree}
        freeSolo
        clearOnBlur
        handleHomeEndKeys
        multiple
        limitTags={6}
        disableCloseOnSelect
        openOnFocus
        selectOnFocus
        onFocus={() => setCategoriesOpen(true)}
        onClose={() => setCategoriesOpen(false)}
        open={categoriesOpen}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              category: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onChange={(event, value) => {
          if (value.length && value[value.length - 1].inputValue) {
            // Create a new value from the user input
            addCategory(value[value.length - 1].inputValue);
            return;
          } else {
            hashtagSelected(value);
          }
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => {
          var open = option.open;
          if (option.children) {
            return (
              <li>
                <GroupHeader>
                  <IconButton aria-label="search hashtag" onClick={() => handleCategorySelected(option)}>
                    <FavoriteBorderIcon />
                  </IconButton>
                  {option.name}
                  <ExpandMore
                    expand={open}
                    aria-expanded={open}
                    aria-label="expand category"
                    onClick={() => {
                      openGroup(option);
                    }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </GroupHeader>
                <Collapse in={open}>
                  <GroupItems>
                    {option.children.map((child) => {
                      var open = child.open;
                      if (child.children) {
                        return (
                          <li style={{ paddingLeft: "10px" }}>
                            <GroupHeader>
                              <IconButton aria-label="search hashtag" onClick={() => handleCategorySelected(child)}>
                                <FavoriteBorderIcon />
                              </IconButton>
                              {child.name}
                              <ExpandMore
                                expand={open}
                                aria-expanded={open}
                                aria-label="expand category"
                                onClick={() => {
                                  openGroup(child);
                                }}
                              >
                                <ExpandMoreIcon />
                              </ExpandMore>
                            </GroupHeader>
                            <Collapse in={open}>
                              <GroupItems>
                                {child.children.map((child) => {
                                  return (
                                    <li key={child.id} style={{ paddingLeft: "20px" }}>
                                      <IconButton aria-label="favourite" onClick={() => handleCategorySelected(child)}>
                                        <FavoriteBorderIcon />
                                      </IconButton>
                                      {child.name}
                                    </li>
                                  );
                                })}
                              </GroupItems>
                            </Collapse>
                          </li>
                        );
                      }
                      return (
                        <li key={child.id} style={{ paddingLeft: "20px" }}>
                          <IconButton aria-label="favourite" onClick={() => handleCategorySelected(child)}>
                            <FavoriteBorderIcon />
                          </IconButton>
                          {child.name}
                        </li>
                      );
                    })}
                  </GroupItems>
                </Collapse>
              </li>
            );
          }
          return (
            <li {...props} autoFocus={option} open={option.open} key={option.id}>
              <IconButton aria-label="favourite">
                <FavoriteBorderIcon />
              </IconButton>
              {option.name}
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Categories"
            helperText={`${selectedCategories.length}/5`}
            placeholder="Add Categories..."
            onClick={() => setCategoriesOpen(true)}
          />
        )}
      />
    </FormGroup>
  );
}
