import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'

const placeholder = "https://i0.wp.com/tsdheadshop.co.uk/wp-content/uploads/woocommerce-placeholder.png"

export default function ProductItem(props) {
  const createPost = (e) => {
    localStorage.setItem("selectedProduct", JSON.stringify(props));
    props.createPost(props, null)
  }

    return (   
    <Card key={props.id} title={props.id} sx={{ width: { xs: '40%', md: 200}, margin: '5px' }} className="product-item">        
      {props.name ? (
      <CardActionArea onClick={createPost}> 
        <CardMedia
          component="img"
          height="140"
          image={`${props.images[0] ? props.images[0].src : placeholder}?w=164&h=164&fit=crop&auto=format`}
          alt="category image"
          loading="lazy"
        />
          <div className="text-light bg-secondary">{'£'+parseFloat(props.price).toFixed(2)}</div>       
          <div className="text-light bg-secondary">{'Stock:'+props.stock_status}</div>               
        <CardContent sx={{ padding: 0}}>
        <h2 className="product-item-header">{props.name}</h2>
        </CardContent>
      </CardActionArea>
      ) : ( <Skeleton variant="rectangular" width={210} height={118} />)
    } 
    </Card>     
    
    )
}
