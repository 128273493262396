import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import AuthService from "../src/services/auth.service"

import initFBSDK from "./utils/initFbSDK.js"

AuthService.getFbAppId().then((id) => {
  initFBSDK(id).then(() => {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById("root")
    );
  });
})

  


