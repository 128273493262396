import React, { Component } from "react";
import { withRouter } from "../../utils/with-router.js";
import AuthService from "../../services/auth.service";
import Box from "@mui/material/Box";
import { Button, ButtonGroup, Typography, Modal } from "@mui/material";
import CreatePost from "../CreatePost/CreatePost";
import CategoryTiles from "./CategoryTiles";
import ProductSearch from "./ProductSearch";
import ImageGrid from "./ImageGrid";
import ProductGrid from "./ProductGrid";

class MyProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: "",
      productData: [],
      currentCategory: "",
      loading: true,
      popupOpen: false,
      value: 0,
      loadingMessage: "",
      totalCount: 0,
      creatingPost: false,
      connected: true,
      random: false,
    };
  }

  // LOAD
  componentDidMount() {
    localStorage.setItem("selectedDraftId", "");
    AuthService.getCurrentUser().then((currentUser) => {
      this.setState({ currentUser: currentUser }, () => {
        if (currentUser.product_count) {
          this.setState({ connected: true, currentCategory: { name: "", id: 0 } });
        } else {
          this.setState({ connected: false });
        }
      });
    });
  }

  // CREATE POST
  createPost = (product, image) => {
    this.props.setTitle("Create Post");
    this.setState(
      {
        productData: [],
        currentImages: [],
        pageNumber: 1,
        selectedProduct: product,
        selectedImage: image,
        loading: true,
      },
      () => this.setState({ creatingPost: true })
    );
  };
  createRandomPost = () => {
    localStorage.setItem("selectedProductId", "");
    localStorage.setItem("selectedProduct", "");
    localStorage.setItem("selectedCategory", this.state.currentCategory.id);
    this.setState({ random: true, creatingPost: true });
    //this.props.router.navigate("/create-post");
  };

  // GENERAL
  tabChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  cancelCreatePost = async (photo) => {
    this.props.setTitle("My Products");
    if (photo?.permalink) {
      window.open(photo.permalink, "_blank", "noopener,noreferrer");
      //this.setState({ instaPostUrl: photo.permalink })
    }
    this.setState({ creatingPost: false, loading: true, random: false });
    //const products = await this.getProducts(this.state.currentCat.id);
    //this.setState({ productData: products ? products : [] });
  };
  closePopup = () => {
    this.setState({ popupOpen: false });
  };
  resetFolders() {
    this.setState({ currentCats: this.state.allCats, productData: [], currentImages: [], pageNumber: 1 });
  }
  productSelected(e) {
    this.setState({ selectedProduct: e.itemData });
  }

  categoryChangeHandler = (category) => {
    this.setState({ currentCategory: category });

    this.setState({
      totalCount: 0,
      jumpToValue: null,
      searchQuery: "",
      pageNumber: 1,
    });
  };

  render() {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: { md: 800, xs: "90%" },
      height: "90%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    return (
      <Box>
        {this.state.creatingPost ? (
          <CreatePost
            user={this.state.currentUser}
            product={this.state.selectedProduct}
            image={this.state.selectedImage}
            cancel={this.cancelCreatePost}
            setLoading={this.props.setLoading}
            fbConnected={this.props.fbConnected}
            random={this.state.random}
          />
        ) : (
          <Box>
            <Modal
              open={this.state.instaPostUrl ? true : false}
              onClose={() => this.setState({ instaPostUrl: "" })}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <iframe src={this.state.instaPostUrl} style={{ height: "100%", width: "100%" }} />
              </Box>
            </Modal>
            <CategoryTiles
              connected={this.state.connected}
              setCurrentCategory={this.categoryChangeHandler}
              currentUser={this.state.currentUser}
            />

            <ProductSearch
              createRandomPost={this.createRandomPost}
              currentUser={this.state.currentUser}
              setProducts={(products, total) => {
                console.log({ total });

                console.log(this.state.totalCount);
                this.setState({ totalCount: total, productData: products, loading: false });
              }}
            />

            <Box id="products">
              <ButtonGroup disabled={this.state.loading}>
                <Button
                  variant={this.state.productsHidden ? "text" : "contained"}
                  onClick={() => this.setState({ productsHidden: false })}
                >
                  Products
                </Button>
                <Button
                  variant={!this.state.productsHidden ? "text" : "contained"}
                  onClick={() => this.setState({ productsHidden: true })}
                >
                  Images
                </Button>
              </ButtonGroup>
              <Typography sx={{ float: "right", marginTop: "10px" }}>
                {this.state.productData.length} / {this.state.totalCount} Products
              </Typography>

              <ProductGrid
                createPost={this.createPost}
                currentCategory={this.state.currentCategory}
                setProducts={(products, total) => {
                  console.log({ total });
                  console.log(this.state.totalCount);
                  this.setState({ totalCount: total, productData: products, loading: false });
                }}
                products={this.state.productData}
                currentUser={this.state.currentUser}
                hidden={this.state.productsHidden}
                totalCount={this.state.totalCount}
              />

              <ImageGrid
                createPost={this.createPost}
                products={this.state.productData}
                hidden={!this.state.productsHidden}
                currentUser={this.state.currentUser}
                totalCount={this.state.totalCount}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export default withRouter(MyProducts);
