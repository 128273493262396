import Box from "@mui/material/Box";
import { ButtonGroup, Button, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductService from "../../../services/product.service";
import AuthService from "../../../services/auth.service";
import PostCard from "./post-card/index.tsx";
import ScheduleService from "../../../services/schedule.service";
import { BaseProps, Post } from "../../../types/global";
import { useNavigate } from "react-router-dom";

type Day = {
  id: number;
  label: string;
  selected: boolean;
};

export const PostQueue = ({ currentUser }: BaseProps) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<Post[]>([]);
  const [view, setView] = useState("posts");
  const [user, setUser] = useState(currentUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      AuthService.getCurrentUser().then((response) => {
        setUser(response);
        getSchedule(response.id);
        getPosts(response.id);
      });
    } else getPosts(currentUser.id);
  }, []);

  const getSchedule = (userId) => {
    ScheduleService.getSchedule(userId).then((response) => {
      console.log(response);
      const schedule = response.schedule;
      const { post1_time, post2_time, post3_time, post4_time } = schedule;
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = schedule;
      handlePostTimeChange(1, post1_time);
      handlePostTimeChange(2, post2_time);
      handlePostTimeChange(3, post3_time);
      handlePostTimeChange(4, post4_time);

      const currentDays = [...days];
      if (monday) {
        currentDays[0].selected = true;
      }
      if (tuesday) {
        currentDays[1].selected = true;
      }
      if (wednesday) {
        currentDays[2].selected = true;
      }
      if (thursday) {
        currentDays[3].selected = true;
      }
      if (friday) {
        currentDays[4].selected = true;
      }
      if (saturday) {
        currentDays[5].selected = true;
      }
      if (sunday) {
        currentDays[6].selected = true;
      }
      setDays(currentDays);
    });
  };

  const handlePostTimeChange = (postId, time) => {
    console.log(time);
    setPostTimes((prevPosts) => prevPosts.map((post) => (post.id === postId ? { ...post, time } : post)));
  };

  const handleDayClick = (dayIndex) => {
    setDays((prevDays) =>
      prevDays.map((day, index) => (index === dayIndex ? { ...day, selected: !day.selected } : day))
    );
  };

  const [hours, setHours] = useState<Day[]>([
    { id: 0, label: "24:00", selected: false },
    { id: 1, label: "01:00", selected: false },
    { id: 2, label: "02:00", selected: false },
    { id: 3, label: "03:00", selected: false },
    { id: 4, label: "04:00", selected: false },
    { id: 5, label: "05:00", selected: false },
    { id: 6, label: "06:00", selected: false },
    { id: 7, label: "07:00", selected: false },
    { id: 8, label: "08:00", selected: false },
    { id: 9, label: "09:00", selected: false },
    { id: 10, label: "10:00", selected: false },
    { id: 11, label: "11:00", selected: false },
    { id: 12, label: "12:00", selected: false },
    { id: 13, label: "13:00", selected: false },
    { id: 14, label: "14:00", selected: false },
    { id: 15, label: "15:00", selected: false },
    { id: 16, label: "16:00", selected: false },
    { id: 17, label: "17:00", selected: false },
    { id: 18, label: "18:00", selected: false },
    { id: 19, label: "19:00", selected: false },
    { id: 20, label: "20:00", selected: false },
    { id: 21, label: "21:00", selected: false },
    { id: 22, label: "22:00", selected: false },
    { id: 23, label: "23:00", selected: false },
  ]);

  const [postTimes, setPostTimes] = useState([
    { id: 1, time: null },
    { id: 2, time: null },
    { id: 3, time: null },
    { id: 4, time: null },
  ]);

  const [days, setDays] = useState<Day[]>([
    { id: 0, label: "monday", selected: false },
    { id: 1, label: "tuesday", selected: false },
    { id: 2, label: "wednesday", selected: false },
    { id: 3, label: "thursday", selected: false },
    { id: 4, label: "friday", selected: false },
    { id: 5, label: "saturday", selected: false },
    { id: 6, label: "sunday", selected: false },
  ]);

  const handleSaveSchedule = () => {
    ScheduleService.setSchedule(user?.id, days, postTimes);
  };

  const handleHourClick = (hour: Day) => {
    const updatedHours = hours.map((h) => {
      if (h.id === hour.id) {
        return { ...h, selected: !hour.selected };
      } else {
        return h;
      }
    });
    setHours(updatedHours);
  };

  // GET PRODUCTS
  const getPosts = async (userId) => {
    console.log(loading);
    
    return ScheduleService.getQueuedPosts(userId)
      .then((response) => {
        if (response) {
          const products = response.data;
          console.log(products);
          setLoading(false);
          setPosts(products);
          //this.getImages(products);

          return products;
        } else {
          setPosts([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openPost = (e) => {
    console.log(e);
    localStorage.setItem("selectedDraftId", e.id);
    navigate("/create-post");
  };

  return (
    <Box>
      <ButtonGroup disabled={loading}>
        <Button variant={view === "posts" ? "contained" : "text"} onClick={() => setView("posts")}>
          Posts
        </Button>
        <Button variant={view === "schedule" ? "contained" : "text"} onClick={() => setView("schedule")}>
          Schedule
        </Button>
      </ButtonGroup>
      {view === "posts" ? (
        <Box id="posts">
          <Typography sx={{ my: 2 }}>Select a post to edit it</Typography>
          {posts?.map((post, index) => {
            return <PostCard key={index} {...post} onClick={openPost} userId={user?.id} getPosts={getPosts}/>;
          })}
        </Box>
      ) : (
        <Box id="schedule">
          <Typography sx={{ my: 2 }}>You can schedule up to 4 posts per day</Typography>
          <Typography sx={{ my: 2 }}>Choose the days you want to post on</Typography>
          <ButtonGroup>
            {days?.map((day) => {
              return (
                <Button
                  key={day.id}
                  variant={day.selected ? "contained" : "outlined"}
                  onClick={() => handleDayClick(day.id)}
                >
                  {day.label}
                </Button>
              );
            })}
          </ButtonGroup>
          <div>
            <Typography sx={{ my: 2 }}>Set the hour to post each day</Typography>

            {postTimes.map((post) => (
              <div key={post.id}>
                <InputLabel htmlFor={`post-${post.id}`}>{`Post ${post.id}`}</InputLabel>
                <Select
                  id={`post-${post.id}`}
                  value={post.time || ""}
                  onChange={(event) => handlePostTimeChange(post.id, event.target.value)}
                >
                  <MenuItem value="">None</MenuItem>
                  {hours.map((hour) => (
                    <MenuItem key={hour.id} value={hour.label}>
                      {hour.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ))}
          </div>
          <Button variant="contained" sx={{ mt: 2 }} onClick={handleSaveSchedule}>
            Save Schedule
          </Button>
        </Box>
      )}
    </Box>
  );
};
