import React, { Component } from "react";
import { withRouter } from '../../utils/with-router.js'
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import Card from '@mui/material/Card'

import AuthService from "../../services/auth.service";
import { Avatar, Button, Container, FormGroup, TextField } from "@mui/material";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {    
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        (response) => {
          console.log(response);
          if(response.connected) this.props.router.navigate("/my-products");
          else this.props.router.navigate("/settings");
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <Container className="col-md-12">
        <Card className="login-card" sx={{ width: { xs: '90%', md: '350px'}, padding: '40px', margin: '100px auto' }}>
          <Avatar sx={{ width: 96, height: 96 }}
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
          >
            <FormGroup className="form-group">
              <TextField
                type="text"
                className="form-control"
                name="username"
                variant="standard"
                label="Username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </FormGroup>

            <FormGroup className="form-group" sx={{ my: 2 }}>
              <TextField
                type="password"
                className="form-control"
                name="password"
                label="Password"
                variant="standard"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </FormGroup>

            <FormGroup className="form-group">
              <Button variant="contained"
                className="btn btn-primary btn-block"
                disabled={this.state.loading} onClick={this.handleLogin}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </Button>
            </FormGroup>

            {this.state.message && (
              <FormGroup className="form-group" sx={{ mt: 2 }}>
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </FormGroup>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </Card>
      </Container>
    );
  }
}

export default withRouter(Login);