import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { Link } from "react-router-dom";

import AuthService from "../../services/auth.service";
import { Avatar, Card, Container, Box, FormGroup, Button, TextField } from "@mui/material";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Your email address is not valid.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        Your username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        Your password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.email,
        this.state.password
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <Container className="col-md-12">
        <Card className="card card-container" sx={{ width: { xs: '90%', md: '350px'}, padding: '40px', margin: '100px auto' }}>
          <Avatar sx={{ width: 96, height: 96 }}
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleRegister}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <Box>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    className="form-control"
                    name="username"
                    label="Username"
                    variant="standard"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </FormGroup>

                <FormGroup className="form-group" sx={{ my: 2 }}>
                  <TextField
                    type="text"
                    className="form-control"
                    name="email"
                    label="Email"
                    variant="standard"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </FormGroup>

                <FormGroup className="form-group" sx={{ my: 2 }}>
                  <TextField
                    type="password"
                    className="form-control"
                    name="password"
                    label="Password"
                    variant="standard"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </FormGroup>

                <FormGroup className="form-group">
                  <Button variant="contained" onClick={this.handleRegister} className="btn btn-primary btn-block">Sign Up</Button>
                </FormGroup>
              </Box>
            )}

            {this.state.message && (
              <FormGroup className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "success"
                      : "error"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </FormGroup>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />            

            {this.state.successful && (              
              <Link to="/login"><Button variant="contained" className="btn btn-primary btn-block" sx={{ my: 2, width: '100%'}}>Click Here to Login</Button></Link>
            )}
          </Form>
        </Card>
      </Container>
    );
  }
}