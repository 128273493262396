// AUTO CREATE CATEGORIES
// AUTO ASSIGN PRODUCTS TO CATEGORIES
// GENERATE CATEGORY DESCRIPTIONS AND AUTO LINK TO PRODUCTS

import { Component, useState } from "react";
import { withRouter } from "../../utils/with-router";
import Categories from "./categories/index.tsx";
import authService from "../../services/auth.service";
import CategoryMapper from "./mapper/index.tsx";

class CategoryManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: "",
      productData: [],
      currentCategory: "",
      loading: true,
      popupOpen: false,
      value: 0,
      loadingMessage: "",
      totalCount: 0,
      creatingPost: false,
      connected: true,
      random: false,
    };
  }

  // LOAD
  componentDidMount() {
    localStorage.setItem("selectedDraftId", "");
    authService.getCurrentUser().then((currentUser) => {
      this.setState({ currentUser: currentUser }, () => {
        if (currentUser.product_count) {
          this.setState({ connected: true, currentCategory: { name: "", id: 0 } });
        } else {
          this.setState({ connected: false });
        }
      });
    });
  }

  render() {
    const mappingCategories = false;
    return (
      <div>
        {mappingCategories ? (
          <CategoryMapper currentUser={this.state.currentUser} />
        ) : (
          <Categories connected={this.state.connected} currentUser={this.state.currentUser} />
        )}
      </div>
    );
  }
}

export default withRouter(CategoryManager);
