import { Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function AddProductTags({ selectedProduct }) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (selectedProduct) getTags();
  }, [selectedProduct.id]);

  const getTags = () => {
    const tags = selectedProduct.categories;
    setTags(tags);
  };

  return (
    <div>
      <Typography>Tags</Typography>
      {tags?.map((tag) => (
        <Chip label={tag.name} />
      ))}
    </div>
  );
}
