import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function InstagramFeed({ images }) {
  return (
    <ImageList cols={3} >
      {images.map((item) => (
        <ImageListItem key={item.src}>
          <img
            src={`${item.src}`}
            alt='alt here'
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}  
