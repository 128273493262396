import React from 'react';
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'
import { green } from '@mui/material/colors'; 

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
});

const LoadingButton = (props) => {
  const { classes, loading, done, ...other } = props;

  if (loading) {
    return (
      <Button className={classes.button} {...other}>
        {other.children}
        <CircularProgress
                                size={24}
                                sx={{
                                  color: green[500],
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
      </Button>
    );
  } else {
    return (
      <Button className={classes.button} {...other} />
    );
  }
}

LoadingButton.defaultProps = {
  loading: false,
  done: false,
  };

export default withStyles(styles)(LoadingButton);