import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import { styled, alpha } from '@mui/material/styles'

import HashtagFinder from "./HashtagFinder";
import CaptionToolbar from './CaptionToolbar'

import CaptionService from '../../services/caption.service'
import Aisha from "../Aisha/compact/index.tsx";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    backgroundColor: 'rgba(255,255,255,0.8)',
    minWidth: 20,
    maxHeight: 250,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const INSTA_CHARACTER_LIMIT = 2200;

const inputRef = React.createRef();

export default function CreatePostCaption(props) {
  const [minCaption, setMinCaption] = useState(false);
  const [emojiOpen, setEmojiOpen] = useState(false)
  const [emojiAnchor, setEmojiAnchor] = useState("")
  const [emojis, setEmojis] = useState([])
  const [originalCaption, setOriginalCaption] = useState("")
  const [captionsAnchor, setCaptionsAnchor] = useState("")
  const [savedCaptions, setSavedCaptions] = useState([])
  const [savedCaptionsOpen, setSavedCaptionsOpen] = useState(false)
  const [captionPos, setCaptionPos] = useState(0)

  useEffect(() => {
    if(!props.product) return;
    var stripped = remove_tags(props.product.description);
    stripped = strip(stripped)

    const caption = props.product.name + "\n\n" + stripped;
    props.setPostCaption(caption);
    setOriginalCaption(caption)

    getSavedCaptions(props.currentUser.id);  
    
    getHashtags(stripped);   
  }, [props.product])


  const getHashtags = (stripped) => {
    CaptionService.getRakeKeywords(stripped.replace(/(\r\n|\n|\r)/gm, ""))
      .then((response) => {
        var hashtags = response;
        var newHashtags = [];
        console.log(hashtags)
        var newEmojis = emojis;
        if (!props.product) newEmojis = [];
        var count = 0;
        hashtags.forEach((element, index) => {
          try {
            if (element.emojis) {
              for (var emoji of element.emojis) {
                Object.keys(emoji).forEach((key) => {
                  emoji[key].forEach((element) => {
                    if (!newEmojis.includes(element)) {
                      newEmojis.push(element)
                    }
                  })
                });
              }
            }
          }
          catch (exception) {
            console.log(element, exception)
          }

          var safe = true;
          if (element.rating != 'safe') {
            safe = false;
          }

          var match = newHashtags.map(function (e) {
            return e.hashtag;
          }).indexOf(element.hashtag);

          if (match == -1) {
            newHashtags.push({ id: count, type: "Auto Generated", hashtag: element.hashtag, rating: "Auto Generated", safe: safe, favourite: false, open: true })
            count++;
          }
        })
        CaptionService.getFavHashtags(props.currentUser.id)
          .then(async (response) => {
            response.forEach(element => {
              var index = newHashtags.map(function (e) {
                return e.hashtag;
              }).indexOf(element.hashtag);

              if (index >= 0) {
                newHashtags[index].favourite = true;
                newHashtags[index].type = "Favourites"
              }
              else {
                newHashtags.splice(0, 0, { id: count + 100, type: "Favourites", hashtag: element.hashtag, rating: 'Favourites', safe: true, favourite: true, open: true })
              }
              count++;
            })
          })
        newHashtags.sort((a, b) => {
          if (a.type == "Favourites") {
            return 1;
          }
          else return 0;
        })
        props.setHashtags(newHashtags)

        setEmojis(newEmojis)
      })
  }

  // CAPTION
  const getSavedCaptions = (user_id) => {
    var _savedCaptions = [];
    CaptionService.getCaptions(user_id)
      .then((response) => {
        response.forEach(element => {
          _savedCaptions.push(element.caption)
        })

        setSavedCaptions(_savedCaptions)
      })
  }
  const openSavedCaptions = (e) => {
    setCaptionsAnchor(e.currentTarget)
    setSavedCaptionsOpen(true)
  }
  const captionChanged = (e) => {
    if (e.target.value.length >= INSTA_CHARACTER_LIMIT) {
      props.openSnackbar("error", "Character limit reached (2200)", true)
    }
    props.setPostCaption(e.target.value)
  }
  const updateCaptionPosition = (e) => {
    setCaptionPos(inputRef.current.selectionStart)
  }
  const saveCaption = (e) => {
    const posStart = inputRef.current.selectionStart;
    const posEnd = inputRef.current.selectionEnd;
    const caption = props.postCaption;
    const userId = props.currentUser.id;

    if (posStart === posEnd) {
      props.openSnackbar("info", "Select some text to save it. (1024 character limit)", true)
      return;
    }

    var selectedCaption = caption.substring(posStart, posEnd);

    CaptionService.saveCaption(userId, selectedCaption)
      .then(() => {
        getSavedCaptions(userId)
        props.openSnackbar("success", "Caption saved.", true)
      })
      .catch((error) => {
        props.openSnackbar("error", "Failed to save caption. Selection too long (1024 char limit).", true)
      })
  }
  const savedCaptionClicked = (e) => {
    if (e.target.innerText != "") {
      var caption = props.postCaption;
      var savedCaption = e.target.innerText;
      var newCaption = caption.concat(savedCaption)

      props.setPostCaption(newCaption)
    }
  }  
  const addHashtagsToCaption = () => {
    return new Promise((resolve) => {
      const _hashtags = props.selectedHashtags;
      console.log(_hashtags)
      var caption = props.postCaption;
      caption.concat("\n\n")
      for (const Hashtag of _hashtags) {
        if (Hashtag.hashtag != "") {
          var newCaption = caption.concat(" ", Hashtag.hashtag)
          caption = newCaption;
        }
      }
      resolve(caption);
    })
  }
  const resetCaption = () => {
    props.setPostCaption(originalCaption);
  };
  const copyCaption = async () => {
    const caption = await addHashtagsToCaption();
    navigator.clipboard.writeText(caption);
  };
  const handleEmojiClick = (e) => {
    setEmojiAnchor(e.currentTarget);
    setEmojiOpen(true);
  };

    // EMOJIS
    const getEmojis = () => {
      CaptionService.getEmojis(props.postCaption)
        .then(_emojis => {
          for (const word of _emojis) {
            if (word) {
              if (word.emojis) {
                for (var emoji of word.emojis) {
                  Object.keys(emoji).forEach((key) => {
                    var oldArray = emojis;
                    emoji[key].forEach((element) => {
                      if (!oldArray.includes(element)) {
                        oldArray.push(element)
                      }
                    })
                    setEmojis(oldArray)
                  });
                }
              }
            }
          }
        })
    }
    const emojiClicked = (e) => {
      var caption = props.postCaption;
      var emoji = "";
      if (e.target) {
        if (e.target.innerText != "") {
          emoji = e.target.innerText;
        }
      }
      else {
        emoji = e.emoji;
      }
  
      var pos = captionPos;
      var newCaption = caption.slice(0, pos) + emoji + caption.slice(pos)
  
      props.setPostCaption(newCaption)
    }
    const handleClose = (e) => {
      setEmojiOpen(false)
      setSavedCaptionsOpen(false)
    }

      // HASHTAGS
  const hashtagsChanged = (e) => {
    props.setHashtags(e.value)
  } 
  const hashtagSelected = (selectedTags) => {
    props.setSelectedHashtags(selectedTags)
  }
  const getHashtagSuggestions = (newTag) => {
    const hashtag = newTag.hashtag.replace('#', '');
    CaptionService.getHashtagSuggestions(hashtag)
      .then(response => {
        var _hashtags = response;
        var newHashtags = props.hashtags;
        _hashtags.forEach(element => {
          var newHashtag = {
            type: "New Suggestions", hashtag: element, rating: 'New Suggestions',
            safe: true, favourite: true
          }

          var find = newHashtags.filter(p => p.hashtag.toLowerCase() == newHashtag.hashtag.toLowerCase())
          if (find.length == 0) {
            newHashtags.splice(0, 0, newHashtag)
          }
        })
        props.setHashtags(newHashtags)
      })
  }
  const addHashtag = (hashtag) => {
    var newHashtag = { type: "Added", hashtag: '#' + hashtag, favourite: false }
    var newHashtags = props.hashtags;
    var selected = props.selectedHashtags

    newHashtags.splice(0, 0, newHashtag)
    selected.push(newHashtag)
    props.setHashtags(newHashtags)
    props.setSelectedHashtags(selected)
  }
  const favHashtagClicked = async (hashtag) => {
    const favTag = hashtag[hashtag.length - 1]

    favTag.favourite = !favTag.favourite;

    await CaptionService.saveFavHashtag(currentUser.id, favTag)
      .then(() => {
        var currentHashtags = props.hashtags;
        var index = currentHashtags.map(function (e) {
          return e.hashtag;
        }).indexOf(favTag.hashtag);

        if (index) {
          currentHashtags[index].favourite = favTag.favourite;
        }

        props.setHashtags(currentHashtags)
      })
  }

  return (
    <Grid id="post-caption" item md={7} xs={12} hidden={props.editVisible}>
      <Box>        
        <CaptionToolbar minCaption={minCaption} setMinCaption={setMinCaption} emojiOpen={emojiOpen} copyCaption={copyCaption} handleEmojiClick={handleEmojiClick} postCaption={props.postCaption}/>
        <Grid container direction="column">
          <Grid item>
            <TextField
              id="post-caption"
              fullWidth
              disabled={props.publishingPost}
              multiline
              helperText={`${props.postCaption.length}/${INSTA_CHARACTER_LIMIT}`}
              minRows={10}
              maxRows={minCaption ? 30 : 15}
              onChange={captionChanged}
              onSelect={updateCaptionPosition}
              value={props.postCaption}
              inputRef={inputRef}
              className="caption-text"
              inputProps={{
                maxLength: 2200,
              }}
            ></TextField>
          </Grid>
          <Grid item sx={{ my: 2 }}>
            <HashtagFinder
              openSnackbar={props.openSnackbar}
              disabled={props.publishingPost}
              hashtags={props.hashtags}
              selectedHashtags={props.selectedHashtags}
              setSelectedHashtags={props.setSelectedHashtags}
              selected={hashtagSelected}
              setHashtags={props.setHashtags}
            />
          </Grid>
          <Grid item sx={{ my: 2 }}>
            <Aisha
            />
          </Grid>
        </Grid>

        <Popover id="emoji-popover" open={emojiOpen} anchorEl={emojiAnchor} onClose={handleClose}>
            <Box className="emoji-menu">
              {emojis?.map((emoji, index) => {
                return (
                  <MenuItem className="emoji-item"
                    key={index}
                    value={emoji}
                    onClick={(event) => emojiClicked(event)}
                  >
                    {emoji}
                  </MenuItem>
                );
              })}
            </Box>
          </Popover>

          {savedCaptions.length ? (
            <div>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={captionsAnchor}
                open={savedCaptionsOpen}
                onClose={handleClose}
              >
                {savedCaptions?.map((caption, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={caption}
                      onClick={(event) => this.captionClicked(event)}
                    >
                      {caption}
                    </MenuItem>
                  );
                })}
              </StyledMenu>
            </div>
          ) : null}
      </Box>
    </Grid>
  );
}
function strip(html) {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  let stripped = doc.body.textContent;
  if (stripped.includes("Packaged Dimensions")) {
    stripped = stripped.split("Packaged Dimensions")[0]
  }
  return stripped;
}
function remove_tags(html) {
  return html
    .replace(/(<(br[^>]*)>)/ig, '\n')
    .replace(/(<([^>]+)>)/ig, '');
}