import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
// MUI
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import SnackBar from "../components/snackBar";
// ICONS
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory2Outlined";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import CakeIcon from "@mui/icons-material/Cake";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import SmartToyIcon from "@mui/icons-material/SmartToy";
// PAGES
import Login from "../pages/Login/Login.js";
import Register from "../pages/Register/Register.js";
import Home from "../pages/Home/Home.js";
import MyProducts from "../pages/MyProducts/MyProducts.js";
import MyBackgrounds from "../pages/MyBackgrounds/MyBackgrounds.js";
import CreatePost from "../pages/CreatePost/CreatePost.js";
import { Settings } from "../pages/Settings/Settings.tsx";

import { PostQueue } from "../pages/CreatePost/Queue/index.tsx";

import FacebookButton from "../components/facebookButton";

import AuthService from "../services/auth.service";
import Aisha from "../pages/Aisha/index.tsx";
import SwiftShopper from "../pages/SwiftShopper/index.tsx";
import StockManager from "../pages/stock-manager/index.tsx";
import { ImportManager } from "../pages/import-manager/index.tsx";
import CategoryManager from "../pages/CategoryManager/index.tsx";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  flexGrow: 1,
  minHeight: 1000,
  padding: "16px 0",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const loggedInUpper = [
  //{ id: 1, text: "Home", icon: <HomeIcon />, path: "home" },
  { id: 2, text: "Create Post", icon: <AddPhotoAlternateIcon />, path: "create-post" },
  { id: 3, text: "My Products", icon: <InventoryIcon />, path: "my-products" },
  { id: 4, text: "Post Queue", icon: <LineWeightIcon />, path: "queue" },
  { id: 5, text: "Ask Aisha", icon: <SmartToyIcon />, path: "aisha" },
  { id: 7, text: "Category Manager", icon: <InventoryIcon />, path: "categories" },
  //{ id: 3, text: "Backgrounds", icon: <WallpaperIcon />, path: "my-backgrounds" },
];
const loggedInLower = [
  { id: 1, text: "Settings", icon: <SettingsIcon />, path: "settings" },
  { id: 2, text: "Logout", icon: <LogoutIcon />, path: "login" },
];

const loggedOutUpper = [{ id: 1, text: "Home", icon: <HomeIcon />, path: "home" }];
const loggedOutLower = [
  { id: 1, text: "Login", icon: <LoginIcon />, path: "login" },
  { id: 2, text: "Create Account", icon: <CakeIcon />, path: "register" },
];

const pageTitles = [
  { path: "/", title: "Products" },
  { path: "/home", title: "Home" },
  { path: "/login", title: "Login" },
  { path: "/my-products", title: "Products" },
  { path: "/register", title: "Create Account" },
  { path: "/settings", title: "Settings" },
  { path: "/my-backgrounds", title: "Backgrounds" },
  { path: "/create-post", title: "Create Post" },
  { path: "/aisha", title: "Ask Aisha" },
  { path: "/queue", title: "Post Queue" },
  { path: "/stock", title: "Stock Manager" },
  { path: "/import", title: "Import Manager" },
  { path: "/swift-shopper", title: "Swift Shopper" },
  { path: "/categories", title: "Category Manager" },
];

function NavigationDrawer(props) {
  const [open, setOpen] = useState(false);
  const [upperNav, setUpperNav] = useState(loggedOutUpper);
  const [lowerNav, setLowerNav] = useState(loggedOutLower);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [currentUser, setCurrentUser] = useState("");
  const [fbConnected, setFbConnected] = useState(false);
  const [fbPages, setFbPages] = useState([]);
  const [selectedFbPage, setSelectedFbPage] = useState("");

  const [loggedInNav, setLoggedInNav] = useState(loggedInUpper);

  // snackbar
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");
  const [snackLink, setSnackLink] = useState("");
  const [snackPath, setSnackPath] = useState("");

  const location = useLocation();

  const mobile = useMediaQuery("(min-width:1700px)");

  // on path change, get user, set nav, set page title
  useEffect(() => {
    AuthService.getCurrentUser().then((currentUser) => {
      setCurrentUser(currentUser);
      if (currentUser) {
        setLowerNav(loggedInLower);
        if (currentUser.connected) {
          if (currentUser.store_url.toLowerCase() === "https://treeofgifts.co.uk") {
            setUpperNav(() => [
              ...loggedInUpper,
              { id: 8, text: "Stock Manager", icon: <InventoryIcon />, path: "stock" },
            ]);
          } else {
            setUpperNav(loggedInUpper);
          }
        } else setUpperNav([]);
      } else {
        setFbConnected(false);
        setUpperNav(loggedOutUpper);
        setLowerNav(loggedOutLower);
      }
    });

    const page = pageTitles.find((obj) => {
      return obj.path === location.pathname;
    });
    setTitle(page.title);
  }, [location.pathname]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [title]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleLinkClick = (e) => {
    const pageName = e.target.textContent;
    if (pageName == "Logout") {
      logOut();
      return;
    }
    if (!mobile) {
      setOpen(false);
    }
  };

  const onOutsideClick = () => {
    if (!mobile) {
      setOpen(false);
    }
  };

  const logOut = () => {
    AuthService.logout();
    if (fbConnected) {
      window.FB.logout();
      setFbConnected(false);
    }
  };

  const openSnackbar = (severity, message, btnHidden, btnMessage, btnPath) => {
    setSnackSeverity(severity);
    setSnackMessage(message);
    setSnackLink(btnMessage);
    setSnackPath(btnPath);
    setSnackOpen(true);
  };

  useEffect(() => {
    const manageStock = localStorage.getItem("manageStock");
    console.log({ manageStock });
    if (manageStock) {
      setLoggedInNav((prevNav) => [
        ...prevNav,
        { id: 8, text: "Stock Manager", icon: <InventoryIcon />, path: "stock" },
      ]);
    } else {
      setLoggedInNav(loggedInUpper);
    }
  }, []);

  return (
    <div>
      <CssBaseline />
      <MuiAppBar position="fixed" open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ backgroundColor: "#c75b39" }}>
          <IconButton size="large" edge="start" aria-label="menu" onClick={handleDrawerOpen} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="mx-3">
            {title}
          </Typography>
        </Toolbar>
      </MuiAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            backgroundColor: "#8d8d8d",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {upperNav.map((link) => (
              <ListItem key={link.id} disablePadding component={Link} to={"/" + link.path} onClick={handleLinkClick}>
                <ListItemButton key={link.name}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {lowerNav.map((link) => (
              <ListItem key={link.id} disablePadding component={Link} to={"/" + link.path} onClick={handleLinkClick}>
                <ListItemButton onClick={handleLinkClick}>
                  <ListItemIcon>{link.icon}</ListItemIcon>
                  <ListItemText primary={link.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          {currentUser ? (
            <FacebookButton
              fbConnected={fbConnected}
              setFbConnected={setFbConnected}
              selectedFbPage={selectedFbPage}
              setSelectedFbPage={setSelectedFbPage}
              fbPages={fbPages}
              setFbPages={setFbPages}
              userId={currentUser.id}
            />
          ) : null}
        </Box>
      </Drawer>
      <Main open={open} onClick={onOutsideClick}>
        <Container>
          <Toolbar />
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Routes>
            <Route path="/" element={<MyProducts />} />
            <Route
              path="/home"
              element={
                <Home
                  selectedFbPage={selectedFbPage}
                  fbConnected={fbConnected}
                  user={currentUser}
                  setLoading={setLoading}
                />
              }
            />
            <Route path="/aisha" element={<Aisha />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/my-products"
              element={
                <MyProducts
                  setTitle={setTitle}
                  setLoading={setLoading}
                  setLoadingMessage={setLoadingMessage}
                  currentUser={currentUser}
                  fbConnected={fbConnected}
                />
              }
            />
            <Route path="/settings" element={<Settings setLoading={setLoading} />} />
            <Route
              path="/create-post"
              element={
                <CreatePost open={open} setLoading={setLoading} currentUser={currentUser} fbConnected={fbConnected} />
              }
            />
            <Route
              path="/my-backgrounds"
              element={<MyBackgrounds openSnackbar={openSnackbar} setLoading={setLoading} currentUser={currentUser} />}
            />
            <Route path="/queue" element={<PostQueue />} />
            <Route path="/stock" element={<StockManager setLoading={setLoading} />} />
            <Route path="/import" element={<ImportManager />} />
            <Route path="/swift-shopper" element={<SwiftShopper />} />
            <Route path="/categories" element={<CategoryManager />} />
          </Routes>
          <SnackBar
            snackOpen={snackOpen}
            message={snackMessage}
            severity={snackSeverity}
            btnMessage={snackLink}
            btnPath={snackPath}
            onClose={() => setSnackOpen(false)}
          />
        </Container>
      </Main>
    </div>
  );
}

export default NavigationDrawer;
