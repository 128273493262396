import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProductService from "../../services/product.service";
import CategoryItem from "./CategoryItems";

const loadingElements = (count) => {
  var elements = [];
  for (var i = 0; i < count; i++) {
    elements.push(i);
  }
  return elements;
};

export default function CategoryTiles(props) {
  const [loading, setLoading] = useState(true);
  const [categoryTree, setCategoryTree] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState(loadingElements(7));
  const [currentCategory, setCurrentCategory] = useState("");

  useEffect(async () => {
    if (!props.currentUser) return;
    getAllCategories();
  }, [props.currentUser]);

  // GET CATEGORIES
  const getAllCategories = () => {
    ProductService.getAllCategories(props.currentUser.id)
      .then((response) => {
        response = response.sort((a, b) => b.count - a.count);
        console.log("Sorted Response:", response);

        const cats = unflatten(response);
        console.log("Unflattened Categories:", cats);

        setCategoryTree(cats);
        setAllCategories(response);

        setCurrentCategories(cats);
        setCurrentCategory({ name: "All Products", id: 0 });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        // Handle the error as needed
        setLoading(false);
      });
  };

  const unflatten = (data) => {
    const buildTree = (items, parentId = 0) => {
      return items
        .filter((item) => item.parent === parentId)
        .map((item) => {
          const children = buildTree(items, item.id);
          if (children.length > 0) {
            item.children = children;
          }
          if (item.name) {
            item.name = item.name.replace(/&amp;/g, "&");
          }
          return item;
        });
    };

    try {
      const unflattenedCategories = buildTree(data);
      console.log("Final Unflattened Categories:", unflattenedCategories);
      return unflattenedCategories;
    } catch (error) {
      console.error("Error during unflattening:", error);
      // Handle the error as needed
      return [];
    }
  };

  const setParentCategories = async () => {
    setCurrentCategories(categoryTree);
    setCurrentCategory({ name: "All Products", id: 0 });
    props.setCurrentCategory({ name: "All Products", id: 0 });

    setLoading(false);

    // reset currentImages / products
    // setloading
  };
  const getParentCategory = () => {
    var parent = allCategories.find((obj) => {
      return obj.id === currentCategory.parent;
    });
    if (!parent) {
      setParentCategories();
      return;
    }

    findGrandparent(parent.id, categoryTree);
  };
  const findGrandparent = (id, array) => {
    for (const cat of array) {
      if (cat.id === id) folderSelected(cat);
      else if (cat.children) findGrandparent(id, cat.children);
    }
  };

  // CATEGORY SELECTED
  const folderSelected = async (e) => {
    var categoryId = e.id;
    var selectedCategory = e;

    setCurrentCategory(selectedCategory);
    setCurrentCategories(selectedCategory.children);

    props.setCurrentCategory(selectedCategory);
  };

  return (
    <Box id="categories">
      <Stack sx={{ my: "20px", flexDirection: { md: "row", xs: "column" } }}>
        <ButtonGroup disabled={loading} sx={{ mb: 2 }}>
          <Button onClick={setParentCategories}>Home</Button>
          <Button onClick={getParentCategory}>Back</Button>
        </ButtonGroup>
        <Typography variant="h3" sx={{ mx: 3 }} color={props.connected ? "white" : "error"}>
          {props.connected ? currentCategory.name : "Store Not Connected"}
        </Typography>
      </Stack>
      <Box id="product-categories" style={{ display: "flex", flexWrap: "wrap" }} className="my-3">
        {currentCategories?.map((category, index) => {
          return <CategoryItem key={index} {...category} loading={loading} click={folderSelected} />;
        })}
      </Box>
    </Box>
  );
}
