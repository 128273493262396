import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/schedule/";

class ScheduleService {
  schedulePost(post) {
    const { draft_id, user_id, product_id, product_name, image_src, caption, hashtags, publish_date, publish_time } =
      post;

    return axios
      .post(API_URL + "schedulePost", {
        draft_id: draft_id,
        user_id: user_id,
        product_id: product_id,
        product_name: product_name,
        image_src: image_src,
        caption: caption,
        hashtags: hashtags,
        publish_date: publish_date,
        publish_time: publish_time,
      })
      .then((response) => {
        const draft = response.data;
        if (draft.id) {
          console.log(draft);
          localStorage.setItem("selectedDraftId", draft.id);
        }
      });
  }

  deletePost(draft_id, user_id) {
    return axios.post(API_URL + "deletePost", { draft_id, user_id }).then((response) => {
      return response;
    });
  }

  queuePost(post) {
    const { draft_id, user_id, insta_id, insta_profile, product_id, product_name, image_src, caption, hashtags } = post;

    return axios
      .post(API_URL + "queue", {
        draft_id: draft_id,
        user_id: user_id,
        insta_id: insta_id,
        insta_profile: insta_profile,
        product_id: product_id,
        product_name: product_name,
        image_src: image_src,
        caption: caption,
        hashtags: hashtags,
      })
      .then((response) => {
        const draft = response.data;
        return response;
      });
  }

  getQueuedPosts(userId) {
    return axios.get(API_URL + `queue/${userId}`, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  getPostById(userId, postId) {
    return axios.get(API_URL + `${userId}/${postId}`, { headers: authHeader() }).then((response) => {
      console.log(response);
      return response;
    });
  }

  setSchedule(userId, days, times) {
    return axios.post(API_URL + `set`, { headers: authHeader(), userId, days, times }).then((response) => {
      console.log(response);
      return response;
    });
  }

  getSchedule(userId) {
    return axios.get(API_URL + `${userId}`, { headers: authHeader() }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
}

export default new ScheduleService();
