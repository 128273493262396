import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import productService from "../../services/product.service";

export default function ImageGrid(props) {
  const [loading, setLoading] = useState(true);
  const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    setCurrentImages([])
  }, [props.totalCount])

  useEffect(() => {
    if(!currentImages.length && props.products) {
      console.log('getting images')
      getImages();
    }
  }, [currentImages, props.products])

  // IMAGES
  const getImages = () => {
    //const products = this.state.productData;
    var allImages = currentImages;
    props.products.forEach((product) => {
      if (product.images) {
        product.images.forEach((images) => {
          allImages.push({ product: product.id, img: images.src, title: product.name });
        });
      }
    });
    console.log({ allImages });
    setCurrentImages(allImages);
    setLoading(false);
  };
  const imageSelected = async (image) => {
    const imageURL = image.img;
    const product = await productService.getProductById(props.currentUser.id, image.product);
    props.createPost(product, imageURL);
  };

  return (
    <Box hidden={props.hidden}>
      <ImageList cols={5} style={{ gap: "0px" }}>
        {currentImages?.map((item, index) => (
          <ImageListItem key={index} onClick={() => imageSelected(item)} style={{ border: "1px solid black" }}>
            <img
              className="imageitems"
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              product={item.product}
              lrgsrc={item.img}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}
