import axios from "axios";
import ProductService from "./product.service";

const API_URL = "/api/auth/";

const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "sign-in", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));

          var user = JSON.parse(localStorage.getItem("user"));
          if (ProductService.getProductCount() != 0) user.synced = true;

          localStorage.setItem("user", JSON.stringify(user));
        }

        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.clear();
  }

  refreshCurrentUser() {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    if (!currentUser) return;
    const username = currentUser.username;

    return axios
      .post(API_URL + "refresh-user", {
        username,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return JSON.parse(localStorage.getItem("user"));
      });
  }

  register(username, email, password) {
    return axios.post(API_URL + "register", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      if (currentUser) {
        resolve(this.refreshCurrentUser());
      } else {
        resolve(currentUser);
      }
    });
  }

  getWooUser(username) {
    return axios
      .post(API_URL + "getWooUser", {
        username,
      })
      .then((response) => {
        if (response.data) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.store_url = response.data.storeurl;
          user.woo_key = response.data.wookey;
          user.woo_secret = response.data.woo_secret;
          localStorage.setItem("user", JSON.stringify(user));

          localStorage.setItem("woo", JSON.stringify(response.data));
        }

        return JSON.parse(localStorage.getItem("woo"));
      });
  }

  getWooApi(username) {
    return axios
      .post(API_URL + "getWooUser", {
        username,
      })
      .then((response) => {
        if (response.data) {
          return new WooCommerceRestApi({
            url: response.storeurl, // Your store URL
            consumerKey: response.wookey, // Your consumer key
            consumerSecret: response.woosecret, // Your consumer secret
            version: "wc/v3", // WooCommerce WP REST API version
          });
        }
      });
  }
  getFbAppId() {
    return axios.get(API_URL + "fb-id").then((response) => {
      return response.data;
    });
  }
}

export default new AuthService();
