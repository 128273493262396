import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const placeholder = "https://i0.wp.com/tsdheadshop.co.uk/wp-content/uploads/woocommerce-placeholder.png";

export default function Category(props) {
  let name = props.name;
  const id = props.id;
  const image = props.image;
  const count = props.count;
  const description = props.description;

  if (name) name = name + (count ? ` (${count})` : "");

  const onClick = () => {
    props.click(props);
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    // Handle edit functionality
    // For example: props.onEdit(id);
    props.edit(props);
  };

  return (
    <Card key={id} sx={{ width: { xs: "45%", md: 200 }, margin: "5px" }} className="category-item">
      {name ? (
        <CardActionArea onClick={onClick} disabled={props.loading}>
          <CardMedia
            component="img"
            sx={{ height: { xs: 0, md: 140 } }}
            image={`${image ? image.src : placeholder}?w=164&h=164&fit=crop&auto=format`}
            alt="category image"
            loading="lazy"
          />
          <CardContent>
            <Typography variant="h6" component="div">
              {name}
            </Typography>
            {description && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  lineHeight: 1.4,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {description}
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <IconButton aria-label="edit" onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </CardActions>
        </CardActionArea>
      ) : (
        <Skeleton variant="rectangular" width={210} sx={{ height: { xs: 50, md: 140 } }} />
      )}
    </Card>
  );
}
