import { useEffect, useState } from "react";

import Resizer from "react-image-file-resizer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

import ImageService from "../../services/image.service";

export default function CreatePostImageToolbar(props) {
  const [file, setFile] = useState();

  const downloadImage = () => {
    const url = selectedImage.url;

    if (isValidHttpUrl(url)) {
      download(url);
    } else {
      ImageService.uploadImage(url).then((response) => {
        download(response);
      });
    }
  };
  const download = (url) => {
    let tempLink = document.createElement("a");
    tempLink.download = new Date().toTimeString() + ".jpeg";
    tempLink.href = url;
    tempLink.target = "_blank";
    tempLink.click();
  };

  useEffect(() => {
    if (!file) return;

    try {
      Resizer.imageFileResizer(
        file.file,
        1000,
        1000,
        "WEBP",
        100,
        0,
        (uri) => {
          if (typeof uri === "string") {
            console.log(uri);
            props.uploadImage(uri);
          }
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.log(err);
    }
  }, [file]);

  const fileUploadHandler = async (e) => {
    if (!e.target.files?.length) {
      return;
    }

    const image = e.target.files[0];

    setFile({ file: image, name: image.name });

    setLoading(true);
  };

  return (
    <Box>
      <Box>
        <Stack direction="row" sx={{ mb: 1 }}>
          <Typography variant="h5" fontWeight={500} width={180}>
            {props.editVisible ? "Editing Image" : "Selected Image"}
          </Typography>
          <ButtonGroup sx={{ mx: 3 }}>
            {!props.editVisible && (
              <>
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  component="label"
                  style={{ backgroundColor: "grey" }}
                >
                  <input type="file" hidden accept="image/*" onChange={fileUploadHandler} />
                  <UploadIcon />
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={downloadImage}
                  style={{ backgroundColor: "grey" }}
                >
                  <DownloadIcon />
                </Button>
              </>
            )}

            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={props.editImage}
              style={{ backgroundColor: "grey" }}
            >
              {props.editVisible ? <EditOffIcon /> : <EditIcon />}
            </Button>
            {props.editVisible ? (
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={() => setImageSaving(true)}
                style={{ backgroundColor: "grey" }}
              >
                <SaveIcon />
              </Button>
            ) : null}
          </ButtonGroup>
        </Stack>
      </Box>
    </Box>
  );
}
