import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import getCroppedImg from './cropImage'
import { withStyles } from '@mui/styles'
import { styles } from './styles'
import { Box, Container } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import LoadingButton from '../../components/loadingButton'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

function ImageCropper({ classes, image, user, saveCroppedBackground, applyCroppedBackground, closePopup }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [backgroundSaving, setBackgroundSaving] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveCroppedImage = useCallback(async () => {
    setBackgroundSaving(true)
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
      saveCroppedBackground(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const applyBackground = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      applyCroppedBackground(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '90%',
    bgcolor: '#040404',
    cursor: 'default',
    color: 'white'
  };

  return (
    <Container sx={style}>
      <Box sx={{ position: 'relative', background: 'grey', height: { xs: "40vh", md: "500px" } }}>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}
          >
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={10}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>        
        <Button
          sx={{ my: 1 }}
          variant="contained"
          color="primary"
          onClick={applyBackground}
          startIcon={<AddPhotoAlternateIcon />}
        >
          Apply Background
        </Button>
        <LoadingButton
          sx={{ my: 1 }}
          variant="contained"
          onClick={saveCroppedImage}
          startIcon={<SaveIcon />}
          loading={backgroundSaving}
          children="Save Background"
        >
        </LoadingButton>
        <Button sx={{ my: 1 }}
          onClick={closePopup}
          variant="outlined"
          color="primary"
          startIcon={<CancelIcon />}
        >
          Close
        </Button>
      </div>
    </Container>
  )
}

export default withStyles(styles)(ImageCropper)