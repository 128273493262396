import axios from "axios";

const API_URL = "/api/recipe/";

class RecipeService {
  create(title, ingredients) {
    return axios
      .post(API_URL + "create", {
        title,
        ingredients,
      })
      .then((response) => {
        return response;
      });
  }

  list() {
    return axios.get(API_URL + "list").then((response) => {
      return response.data;
    });
  }

  search(title) {
    return axios.get(API_URL + `search/${title}`).then((response) => {
      return response.data;
    });
  }
}

export default new RecipeService();
