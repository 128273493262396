import { Container, Typography, TextField } from "@mui/material";
import React from "react";

export default function AddProductDescription({ description, setDescription }) {
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Container>
      <Typography variant="h5" fontWeight={500} width={120}>
        Description
      </Typography>
      <TextField
        id="post-caption"
        fullWidth
        multiline
        minRows={10}
        className="caption-text"
        inputProps={{
          maxLength: 2200,
        }}
        value={description}
        onChange={handleDescriptionChange}
      ></TextField>
    </Container>
  );
}
