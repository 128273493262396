import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { Box, Button, Select, MenuItem, Typography } from "@mui/material";
import FacebookService from "../services/facebook.service";
import UserService from "../services/user.service";

export default function FacebookButton({
  fbConnected,
  setFbConnected,
  selectedFbPage,
  setSelectedFbPage,
  fbPages,
  setFbPages,
  userId,
}) {
  const location = useLocation();

  useEffect(() => {
    FacebookService.getFBStatus().then((response) => {
      if (response.status === "connected") {
        setFbConnected(true);
        getFbPages();
      } else {
        setFbConnected(false);
        setFbPages([]);
        setSelectedFbPage(null);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem("fbConnected", fbConnected.toString());
  }, [fbConnected]);

  useEffect(() => {
    if (!selectedFbPage) return;
    console.log({ selectedFbPage });
    FacebookService.getInstagramAccountId(selectedFbPage, FacebookService.getToken()).then((instaId) => {
      console.log(instaId);
      UserService.setInstaId(userId, instaId);
    });
  }, [selectedFbPage]);

  const logInToFB = () => {
    FacebookService.logInToFB(userId).then(() => {
      setFbConnected(true);
      getFbPages();
    });
  };

  const getFbPages = () => {
    FacebookService.getFacebookPages().then((response) => {
      setFbConnected(true);
      setFbPages(response);
      setSelectedFbPage(response[0].id);
    });
  };

  const logOutOfFB = () => {
    FacebookService.logOutOfFB().then(() => {
      setFbConnected(false);
      setSelectedFbPage("");
      setFbPages([]);
    });
  };

  return (
    <Box sx={{ margin: "10px" }}>
      <Box hidden={!fbConnected}>
        <Button
          onClick={logOutOfFB}
          sx={{ backgroundColor: "rgba(56,117,234,255)", width: "100%", mb: 2 }}
          variant="contained"
          startIcon={<FacebookRoundedIcon />}
        >
          Logout of Facebook
        </Button>
        <Typography variant="h6">Default Page</Typography>
        <Select
          id="fb-page-select"
          value={selectedFbPage}
          onChange={(e) => setSelectedFbPage(e.target.value)}
          sx={{ width: "100%" }}
        >
          {fbPages?.map((page, index) => {
            return (
              <MenuItem value={page.id} key={index}>
                {page.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box hidden={fbConnected}>
        <Button
          onClick={logInToFB}
          sx={{ backgroundColor: "rgba(56,117,234,255)", width: "100%", mt: 2 }}
          variant="contained"
          startIcon={<FacebookRoundedIcon />}
        >
          Connect Facebook
        </Button>
      </Box>
    </Box>
  );
}
