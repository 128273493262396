import axios from "axios";
import authHeader from "./auth-header";
const USER_API_URL = "/api/user/";
class FacebookService {
  getInstaPosts = (instagramAccountId) => {
    return new Promise(async (resolve) => {
      window.FB.api(`${instagramAccountId}/media`, (response) => {
        var count = 0;
        var photos = [];
        response.data.forEach((element) => {
          this.getInstaPhoto(element.id).then((photo) => {
            count++;
            if (photo.media_type != "VIDEO") photos.push({ src: photo.media_url, timestamp: photo.timestamp });
            if (count === response.data.length) {
              photos.sort(compare);
              resolve(photos);
            }
          });
        });
      });
    });
  };

  getInstaPhoto = (mediaId) => {
    return new Promise((resolve) => {
      window.FB.api(`${mediaId}?fields=id,media_type,media_url,timestamp,permalink,shortcode`, (response) => {
        resolve(response);
      });
    });
  };

  getFBStatus = () => {
    return new Promise((resolve) => {
      window.FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
  };

  getToken = () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        return response.authResponse?.accessToken;
      }
    });
  };

  logInToFB = (userId) => {
    return new Promise((resolve) => {
      window.FB.login(
        (response) => {
          if (response && !response.error) {
            console.log({ response });

            this.setFbToken(userId, response.authResponse.accessToken);

            resolve(response.status);
          }
        },
        {
          // Scopes that allow us to publish content to Instagram
          scope: "instagram_basic,pages_show_list",
        }
      );
    });
  };

  setFbToken(userId, token) {
    return axios.post(USER_API_URL + "fb-token", { headers: authHeader(), userId, token }).then((response) => {
      console.log(response);
    });
  }

  logOutOfFB = () => {
    return new Promise((resolve) => {
      window.FB.logout((response) => {
        resolve(response.status);
      });
    });
  };

  getFacebookPages = async () => {
    const token = this.getToken();
    return new Promise((resolve) => {
      window.FB.api("me/accounts", { access_token: token }, (response) => {
        if (response && !response.error) {
          var pages = [];
          for (const page of response.data) {
            pages.push({ id: page.id, name: page.name });
          }
          resolve(pages);
        }
      });
    });
  };

  getInstagramAccountId = async (facebookPageId, token) => {
    console.log({ facebookPageId, token });
    return new Promise((resolve) => {
      window.FB.api(
        facebookPageId,
        {
          access_token: token,
          fields: "instagram_business_account",
        },
        (response) => {
          console.log(response)
          if (response && !response.error && response.instagram_business_account) {
            resolve(response.instagram_business_account.id);
          } else resolve()
        }
      );
    });
  };

  /*

    {message: 'An unknown error has occurred.', type: 'OAuthException', code: 1, fbtrace_id: 'AQ1CSQZSX5vGxzh5jtDEVd9'}
    invalid image url

    */

  createMediaObjectContainer = (instagramAccountId, imageUrl, postCaption, token) => {
    console.log({ instagramAccountId, imageUrl, postCaption, token });
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: token,
          image_url: imageUrl,
          caption: postCaption,
        },
        (response) => {
          if (response.error) {
            reject(response.error.message);
          }
          console.log({ fbResponse: response });
          resolve(response.id);
        }
      );
    }).catch((error) => {
      console.log(error);
    });
  };

  publishMediaObjectContainer = (instagramAccountId, mediaObjectContainerId) => {
    return new Promise((resolve) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          resolve(response);
        }
      );
    }).catch((error) => {
      console.log(error);
    });
    return new Promise((resolve) => {
      resolve({ id: instagramAccountId });
    });
  };
}

function compare(a, b) {
  if (a.timestamp > b.timestamp) {
    return -1;
  }
  if (a.timestamp < b.timestamp) {
    return 1;
  }
  return 0;
}

export default new FacebookService();
