import axios from "axios";
import authHeader from "./auth-header";
import { createApi } from "unsplash-js";

const API_URL = "/api/images/";

const unsplash = createApi({
  accessKey: "GSGXFZiwkcbXabIKGbWp5EJKpRALl-xLy_D1ARoMOFE",
});

class ImageService {
  /* ASSET BACKGROUNDS */

  getPixabayImages(searchTerm, page) {
    console.log(page);
    return axios
      .get(
        `https://pixabay.com/api/?key=30238530-634765629db068dafd920f932&image_type=photo&q=${searchTerm}&per_page=30&page=${page}`
      )
      .then((response) => {
        return response.data;
      });
  }

  createProductBackground(image, prompt) {
    console.log({ image, prompt });
    return axios
      .post("http://localhost:9000/images/" + "create-background", {
        prompt,
        image,
      })
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  getUnsplashImages(searchTerm, page) {
    if (searchTerm) {
      return unsplash.search.getPhotos({
        query: searchTerm,
        page: page,
        perPage: 30,
      });
    } else {
      return unsplash.photos.list({
        page: page,
        perPage: 30,
      });
    }
  }

  deleteBackground(user_id, url) {
    return axios
      .post(API_URL + "delete-background", {
        headers: authHeader(),
        user_id,
        url,
      })
      .then((response) => {
        return "done";
      });
  }
  saveBackground(userId, url) {
    return axios.post(API_URL + "save-background", { headers: authHeader(), url, userId });
  }
  saveCroppedBackground(user_id, base64img) {
    return axios
      .post(API_URL + "save-cropped-background", { headers: authHeader(), user_id, base64img })
      .then((response) => {
        if (!response) return "done";
        else {
          if (response.data == "MaxLimit") {
            return response.data;
          }
        }
        return "";
      });
  }

  /* REMOVE PRODUCT BACKGROUND */
  // REMOVE WHITE USING PYTHON
  removeBackground(url) {
    return axios.post(API_URL + "remove-background", { headers: authHeader(), url }).then((response) => {
      console.log(response);
      return response.data;
    }).catch(error => {
      console.log({ error })
      return error;
    });
  }

  removeWhite(url, threshold, blur) {
    console.log(threshold, blur);
    return axios.post(API_URL + "remove-white", { headers: authHeader(), url, threshold, blur }).then((response) => {
      console.log(response);
      return response.data.imageSrc;
    });
  }

  adjustImage(url) {
    return axios.post(API_URL + "adjust-image", { headers: authHeader(), url }).then((response) => {
      console.log(response);
      return response.data.imageSrc;
    });
  }

  /* CONVERT IMAGE */

  compressImage(image) {
    return axios.post(API_URL + "compress-image", { headers: authHeader(), image }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
  async imgToDataUrl(url) {
    try {
      const response = await axios.post(API_URL + "img-to-data-URL", { headers: authHeader(), url });
      localStorage.setItem("selectedImageDataURL", response.data);
      return response.data;
    } catch (error) {
      return console.log(error);
    }
  }
  getDataURL() {
    return localStorage.getItem("selectedBgURL");
  }
  async bgToDataURL(src) {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = async function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      localStorage.setItem("selectedBgURL", dataURL);
      console.log("convert done");
      return dataURL;
    };
    image.src = src;
  }

  /* UPLOAD IMAGE */
  uploadImage(base64img, filename) {
    return axios.post(API_URL + "upload-image", { headers: authHeader(), base64img, filename }).then((response) => {
      console.log(response);
      return response.data;
    });
  }

  /* ARCHIVE */

  resizeImage(imagePath, downloadName, newHeight, newWidth) {
    return axios
      .post(API_URL + "resizeImage", { headers: authHeader(), imagePath, downloadName, newHeight, newWidth })
      .then((response) => {});
  }
  detectWhite(url) {
    return axios.post(API_URL + "detectWhite", { headers: authHeader(), url });
  }
  async runInstaImageScan(page_size, page_number) {
    var allImages = JSON.parse(localStorage.getItem("allImages"));

    var images = await paginate(allImages, page_size, page_number);

    return axios
      .post(API_URL + "runInstaImageScan", { headers: authHeader(), images })
      .then((response) => {
        /*       
        images.forEach(newElement => {
          console.log('updating images');
          allImages.forEach(oldElement => {
            if(newElement.Id === oldElement.Id)
            {
              element.WhiteBg = newImage.WhiteBg
            }
          })
          allImages = updateImage(allImages, element);
          console.log(allImages);
        })*/

        response.data.forEach((element) => {
          var objIndex = allImages.findIndex((obj) => obj.Id == element.Id);
          allImages[objIndex].WhiteBg = element.WhiteBg;
        });

        var newInstaImages = response.data.filter((p) => p.WhiteBg == false);
        var instaImages = [];

        try {
          instaImages = JSON.parse(localStorage.getItem("instaImages"));
        } catch (error) {
          console.log(error);
        }

        if (instaImages == null) instaImages = [];

        newInstaImages.forEach((element) => {
          instaImages.push(element);
        });

        localStorage.setItem("instaImages", JSON.stringify(instaImages));
        var instaImageCount = JSON.parse(localStorage.getItem("instaImages")).length;

        var instaScanCount = 0;
        try {
          var count = parseInt(localStorage.getItem("instaScanCount"));
          if (isNaN(count)) count = 0;
          instaScanCount = count + page_size;
        } catch {
          localStorage.setItem("instaScanCount", "0");
          instaScanCount = page_size;
        }

        localStorage.setItem("instaScanCount", instaScanCount.toString());

        localStorage.setItem("allImages", JSON.stringify(allImages));

        var respo = { ImageCount: instaImageCount, ScanCount: instaScanCount };

        return respo;

        /*if(instaScanCount != this.getImageCount()) {
          this.runInstaImageScan(page_size, page_number++)
        }
        else { 
          return instaImages.length;
        }*/
      })
      .catch((error) => {
        return "error";
      });
  }

  getInstaImageCount() {
    if (localStorage.getItem("instaImages")) {
      var images = JSON.parse(localStorage.getItem("instaImages"));
      if (!images) localStorage.setItem("instaImages", "");
      return images.length;
    } else return 0;
  }

  getInstaImageScanCount() {
    if (!isNaN(localStorage.getItem("instaScanCount"))) {
      return localStorage.getItem("instaScanCount");
    } else return 0;
  }
}

export default new ImageService();

function dataURLtoFile(dataurl, filename) {
  console.log(dataurl);
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
