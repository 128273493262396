
import React, { Component, useEffect, useState } from "react";

import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { BaseProps } from "../../Settings/Settings";

const querystring = require("querystring");

const endpoint = "/wc-auth/v1/authorize";

const CONSUMER_KEY = process.env.WOO_CONSUMER_KEY;
const SECRET_KEY = process.env.WOO_SECRET_KEY;

const WOO_CONSUMER_KEY = "ck_ba24dc24332a6ddb1a7f487fbc641e21ad07420d";
const WOO_SECRET_KEY = "cs_1dd38ecf05e27abf9d825629c7e9122892145c99";

export const WooSetup = ({ currentUser }: BaseProps) => {
  const [storeURL, setStoreURL] = useState("");
  const [consumerKey, setConsumerKey] = useState(CONSUMER_KEY || WOO_CONSUMER_KEY || "");
  const [secretKey, setSecretKey] = useState(SECRET_KEY || WOO_SECRET_KEY || "");

  const connectToWooClick = (e) => {
    if (!currentUser) return;
    if (storeURL && !consumerKey) {
      const storeUrl = "https://" + storeURL;
      const params = {
        app_name: "Product 2 Post",
        scope: "read_write",
        user_id: currentUser.id,
        return_url: "https://post.heyi.app/settings",
        callback_url: "https://post.heyi.app/api/woo/wooAuth",
      };
      const query_string = querystring.stringify(params).replace(/%20/g, "+");

      const wooAuthURL = storeUrl + endpoint + "?" + query_string;

      window.open(wooAuthURL, "_blank", "noopener,noreferrer");

      UserService.setStoreURL(currentUser.username, storeUrl);
    } else if (consumerKey && secretKey) {
      UserService.setWooKeys(currentUser.id, consumerKey, secretKey).then((response) => {
        console.log({ response })
        window.location.reload();
      });
    }
  };

  const handleStoreURLChange = (e) => {
    const { value } = e.target;

    setStoreURL(value);
  };

  const handleConsumerKeyChange = (e) => {
    const { value } = e.target;

    setConsumerKey(value);
  };

  const handleSecretKeyChange = (e) => {
    const { value } = e.target;

    setSecretKey(value);
  };

  return (
    <Box>
      {currentUser?.store_status != "connected" && (
        <Box>
          <div>
            <Typography variant="h4" sx={{ my: 2 }}>
              Please enter your WooCommerce Store URL
            </Typography>
            <TextField
              fullWidth
              label="WooCommerce Store"
              placeholder="Enter your store URL..."
              onChange={handleStoreURLChange}
              value={`${storeURL}`}
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
            />
            <Typography variant="h5" sx={{ my: 2 }}>
              OR enter your consumer & secret key
            </Typography>
            <TextField
              label="Consumer Key"
              placeholder="Enter your WooCommerce Consumer Key..."
              onChange={handleConsumerKeyChange}
              value={consumerKey}
            />
            <TextField
              label="Secret Key"
              placeholder="Enter your WooCommerce Secret Key..."
              onChange={handleSecretKeyChange}
              value={secretKey}
            />
          </div>

          <Button variant="contained" color="success" sx={{ my: 2 }} onClick={connectToWooClick}>
            Connect to WooCommerce
          </Button>
        </Box>
      )}
    </Box>
  );
};
