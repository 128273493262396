import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "/api/import/";

class ImportService {
  getCategories() {
    return axios.get(API_URL + "categories", { headers: authHeader() }).then((response) => {
      console.log(response);

      return response.data;
    });
  }

  importProducts(userId) {
    return axios.get(API_URL + `products/${userId}`, { headers: authHeader() }).then((response) => {
      console.log(response);
      return response.data;
    });
  }
}

export default new ImportService();
