import { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
// ICONS
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ShuffleIcon from "@mui/icons-material/Shuffle";

import ProductService from "../../services/product.service";

export default function ProductSearch(props) {
  const [searchQuery, setSearchQuery] = useState("");

  // SEARCH
  const getSearchResults = () => {
    console.log({ searchQuery });
    if (searchQuery) {
      ProductService.searchForProduct(props.currentUser.id, searchQuery)
        .then((response) => {
          console.log({ response });
          props.setProducts(response, 0);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      props.setProducts([], 0);
    }
  };
  const searchChanged = (e) => {
    setSearchQuery(e.target.value);
  };
  const searchEnter = (e) => {
    if (e.code === "Enter") {
      getSearchResults();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <Box id="product-search" sx={{ my: "20px" }}>
      <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
        <IconButton sx={{ p: "10px" }} aria-label="menu">
          <MenuIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Products"
          onChange={searchChanged}
          inputProps={{ "aria-label": "search products" }}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={getSearchResults}>
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton color="primary" sx={{ p: "10px" }} aria-label="random product" onClick={props.createRandomPost}>
          <ShuffleIcon />
        </IconButton>
      </Paper>
    </Box>
  );
}
