import React, { Component } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Delete from "@mui/icons-material/Delete";
import ScheduleService from "../../../../services/schedule.service";

const placeholder = "https://i0.wp.com/tsdheadshop.co.uk/wp-content/uploads/woocommerce-placeholder.png";

export default function PostCard(props) {
  const createPost = (e) => {
    props.onClick(props, null);
  };

  const deletePost = (e) => {
    e.stopPropagation(); // Prevent the click event from triggering the parent click event
    // Perform delete action here
    console.log("Deleting post:", props.id);
    console.log(props);
    ScheduleService.deletePost(props.id, props.userId).then((response) => {
      console.log(response);
      props.getPosts(props.userId);
    });
  };
  return (
    <Card
      key={props.id}
      title={props.product_name}
      sx={{ width: { xs: "40%", md: 200 }, margin: "5px" }}
      className="product-item"
    >
      <CardActionArea onClick={createPost}>
        <Delete
          color="error"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: 1,
            transition: "opacity 0.3s",
            "&:hover": {
              opacity: 0.6,
              cursor: "pointer",
            },
          }}
          onClick={deletePost}
        />
        <CardMedia
          component="img"
          height="140"
          image={`${props.image_src || placeholder}?w=164&h=164&fit=crop&auto=format`}
          alt="post image"
          loading="lazy"
        />

        <CardContent sx={{ padding: 0 }}>
          <h2 className="product-item-header">{props.product_name}</h2>
          <h4 className="profile-header">{props.insta_profile}</h4>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
