import React, { useState } from "react";

import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

import InstaService from '../../services/caption.service';

const INSTA_HASHTAG_LIMIT = 30;

const filter = createFilterOptions();

const GroupHeader = styled('div')(({ theme }) => ({
  zIndex: 999,
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function HashtagFinder({ currentUser, disabled, setSelectedHashtags, selectedHashtags, setHashtags, hashtags, openSnackbar }) {
  const [hashtagsOpen, setHashtagsOpen] = useState(false)
  
  const isHashtagBanned = (hashtags, index) => {
    const element = hashtags[index]
    InstaService.isHashtagBanned(element.hashtag).then(response => {
      element.type = response.data;
      hashtags.splice(index, 1, element)
      index++;
      isHashtagBanned(hashtags, index)
    })
  }

  const hashtagsChanged = (e) => {
    setHashtags(e.value)
  }

  const hashtagSelected = (selectedTags) => {
    const newTag = selectedTags[selectedTags.length - 1]
    if (selectedTags.length > INSTA_HASHTAG_LIMIT) {
      openSnackbar("error", "Max HashTag Limit Reached (30)", true)
      return;
    }
    setSelectedHashtags(selectedTags)
  }

  const getHashtagSuggestions = (event, newTag) => {
    const hashtag = newTag.hashtag.replace('#', '');
    InstaService.getHashtagSuggestions(hashtag)
      .then(response => {
        var _hashtags = response;
        var newHashtags = [...hashtags];
        var count = newHashtags.length;
        _hashtags.forEach((element) => {
          var newHashtag = { id: count, type: "New Suggestions", hashtag: element, rating: 'New Suggestions', safe: true, favourite: false, open: true }
          count++;
          if (newHashtag.hashtag) {
            var find = newHashtags.filter(p => p.hashtag?.toLowerCase() == newHashtag.hashtag.toLowerCase())
            if (find.length == 0) {
              newHashtags.splice(0, 0, newHashtag)
            }
          }
        })
        setHashtags(newHashtags)
      })
  }

  const addHashtag = async (hashtag) => {
      var _hashtags = [...hashtags];
      hashtag = formatHashtag(hashtag)
      var newHashtag = { type: "Added", hashtag: hashtag, rating: "Added", safe: true, favourite: false, open: true }
      _hashtags.splice(0, 0, newHashtag)
      var _selected = [...selectedHashtags]
      _selected.push(newHashtag)
      setHashtags(_hashtags)
      setSelectedHashtags(_selected)    
  }

  const favHashtagClicked = async (hashtag) => {
    const favTag = hashtag

    favTag.favourite = !favTag.favourite;

    await InstaService.saveFavHashtag(currentUser.id, favTag)
      .then(() => {
        var currentHashtags = [...hashtags];
        var index = currentHashtags.map(function (e) {
          return e.hashtag;
        }).indexOf(favTag.hashtag);

        if (index) {
          currentHashtags[index].favourite = favTag.favourite;
        }

        setHashtags(currentHashtags)
      })
  }

  const openGroup = async (group) => {
    var _hashtags = [...hashtags]

    _hashtags = await _hashtags.filter(p => p.type == group);
    var index = hashtags.map((e) => {
      return e.hashtag;
    }).indexOf(_hashtags[0].hashtag)

    var tags = [...hashtags]
    tags[index].open = !tags[index].open;
    setHashtags(tags)
  }

  return (
    <FormGroup className="mb-2">
      <Autocomplete disabled={disabled}
        className="mt-2" style={{ maxWidth: '100%' }}
        id="multiple-limit-tags"
        value={selectedHashtags}
        options={hashtags}
        loading
        loadingText="Generating Suggestions..."
        freeSolo
        clearOnBlur
        handleHomeEndKeys
        multiple
        limitTags={6}
        disableCloseOnSelect
        openOnFocus
        selectOnFocus
        onFocus={() => setHashtagsOpen(true)}
        onClose={() => setHashtagsOpen(false)}
        groupBy={(option) => {
          if (option.type) {
            return option.type;
          }
        }}
        open={hashtagsOpen}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.hashtag);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              hashtag: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onChange={(event, value) => {
          if (value.length && value[value.length - 1].inputValue) {
            // Create a new value from the user input
            addHashtag(value[value.length - 1].inputValue)
            return;
          }
          else {
            hashtagSelected(value)
          }
        }
        }
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.hashtag;
        }}
        renderGroup={(params) => {
          if (!params.group) {
            return (
              <GroupItems>{params.children}</GroupItems>
            )
          }
          var open = params.children[0].props.open;
          return (
            <li>
              <GroupHeader>{params.group}<ExpandMore
                expand={open}
                onClick={() => {
                  openGroup(params.group)
                }}
                aria-expanded={open}
                aria-label="ask pete-e"
              >
                <ExpandMoreIcon />
              </ExpandMore></GroupHeader>
              <Collapse in={open}>
                <GroupItems>{params.children}</GroupItems>
              </Collapse>
            </li>
          )
        }
        }
        renderOption={(props, option) => {
          return (
            <li {...props} autoFocus={option} open={option.open} key={option.id}>
            <IconButton aria-label="search hashtag" onClick={(event) => {
              getHashtagSuggestions(event, option)
            }
            }>
              <SearchIcon />
            </IconButton>
            <IconButton aria-label="favourite" onClick={() => {
              favHashtagClicked(option)
            }
            }>
              {option.favourite ?
                <FavoriteIcon />
                :
                <FavoriteBorderIcon />
              }
            </IconButton>
            {option.hashtag}
          </li>
          )
        }
          
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" color={option.safe ? 'success' : 'error'} label={option.hashtag} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label="#HashTags" helperText={`${selectedHashtags.length}/${INSTA_HASHTAG_LIMIT}`} placeholder="Add Hashtags..." onClick={() => setHashtagsOpen(true)} />
        )}
      />
    </FormGroup>
  )
}

function cleanKeyword(keyword) {
  keyword = keyword.replace(/[^a-zA-Z]/g, '')
  keyword = keyword.replace(/ /g, "");
  return keyword;
}
 
 function formatHashtag(string) {
  const newString = cleanKeyword(string);
  if(newString == "") return;
  const words = newString.split(",");
  for (let i = 0; i < words.length; i++) {
   words[i] = "#" + words[i];
  }
  return words.join(" ");
 }
