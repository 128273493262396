import axios from "axios";
import authHeader from './auth-header';

const API_URL = "/api/caption/";

class CaptionService { 
  saveFavHashtag(user_id, hashtag) {
    return axios.post(API_URL + 'save-hashtag', { headers: authHeader(), user_id, hashtag })
    .then((response) => {
      return response.data
    })
  }

  saveCaption(user_id, caption) {
    return axios.post(API_URL + 'save-caption', { headers: authHeader(), user_id, caption })
    .then((response) => {
      console.log(response);
      return response;
    })
  }

  getCaptions(userId) {
    return axios.get(API_URL + `captions/${userId}`)
    .then((response) => {
      return response.data;
    })
  }

  getRakeKeywords(text) {
    var keywordList = [];   
    var convertedList = [];
    var excludedList = [];

    return axios.post(API_URL + `keywords`, { headers: authHeader(), text })
    .then((response) => {
      // get keywords from rake
      //console.log('Rake Response: ')
      //console.log(response.data);     

      convertedList = response.data;

      //console.log(convertedList);
      //console.log(keywordList)
      return convertedList;
    })      
  }

  getFavHashtags(userId) {
    return axios.get(API_URL + `saved-hashtags/${userId}`, { headers: authHeader() })
    .then((response) => {
      console.log(response);
      return response.data
    })
  }

  async getKeywords(text) {     
    // generated hashtags
    // suggested hashtags
    // questionable hashtags
    // banned hashtags
    var excludedHashtags = [];

    const tokenizer = new EnglishTokenizer()
    const keywordExtractor = new KeywordExtractor()

    keywordExtractor.setTokenizer(tokenizer)

    keywordExtractor.addDocument(1, text)

    var keywordList = [];   
    var convertedList = [];
    

  return axios.get(API_URL + `keywords/${text}`, { headers: authHeader() })
  .then(async (response) => {
    // get keywords from rake      

    const extraction_result =    
    keyword_extractor.extract(text,{
        language:"english",
        remove_digits: true,
        return_changed_case:true,
        return_chained_words: false,
        remove_duplicates: false,
    });

    keywordList = extraction_result;

    const keywords = keywordExtractor.extractKeywords(text, {
    sortByScore: true,
    limit: 50
    })      

    keywords.forEach((element) => {
      keywordList.push(element[0].toLowerCase())
    })

    response.data.forEach(element => {
      const wordCount = element.split(' ').length;
      if(wordCount > 3) {
        excludedHashtags.push(element) 
      }
      else if(wordCount > 1) {
        const tag = getHashtag(element.toLowerCase())
        if(tag) { 
          convertedList.push(getHashtag(element.toLowerCase()))        
        }
      }
      else {      
         keywordList.push(element.toLowerCase())
      }   
    })

    keywordList.forEach((element, index) => {
      if(StopWords.includes(element)) {
        keywordList.splice(index, 1)
      }
    })
    
    const toFindDuplicates = keywordList => keywordList.filter((item, index) => keywordList.indexOf(item) !== index)

    const duplicates = toFindDuplicates(keywordList)

    duplicates.forEach(element => {      
      const converted = getHashtag(element)
      if(!converted) return;

      const find = convertedList.filter(p => p.hashtag == converted.hashtag);
      if(find.length == 0) {
        convertedList.push(converted)
      }      
    })

    keywordList.forEach(element => {
      const converted = addHashtag(element)

      const tag = { hashtag: converted, type: 'generated' }
      const find = convertedList.filter(p => p.hashtag == converted);
      if(find.length == 0) {
        convertedList.push(tag)
      }      
    })    
    
    return convertedList; 
  })  
  }

  getHashtagSuggestions(text) {
    const url = `https://ritekit-ritekit-v1.p.rapidapi.com/stats/hashtag-suggestions/${encodeURIComponent(text)}`
    const options = {
      method: 'GET',
      url: url,
      headers: {
        'X-RapidAPI-Key': 'e267d25c61msh5dd5789911038c9p144921jsn379659d07175',
        'X-RapidAPI-Host': 'ritekit-ritekit-v1.p.rapidapi.com'
      }
    };
    
    return axios.request(options).then(function (response) {
      var keywordList = response.data.data;
      var convertedList = [];

      keywordList.forEach(element => {
        const converted = addHashtag(element.hashtag)
        if(!convertedList.includes(converted))
        convertedList.push(converted)
      })
  
      return convertedList;
    }).catch(function (error) {
      console.error(error);
    });
  }

  getEmojisFromText(text) {
    const options = {
      method: 'GET',
      url: 'https://ritekit-ritekit-v1.p.rapidapi.com/emoji/suggestions',
      params: {text: text},
      headers: {
        'X-RapidAPI-Key': 'e267d25c61msh5dd5789911038c9p144921jsn379659d07175',
        'X-RapidAPI-Host': 'ritekit-ritekit-v1.p.rapidapi.com'
      }
    };
    
    axios.request(options).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }

  emojifyText(text) {
    const options = {
      method: 'GET',
      url: 'https://ritekit-ritekit-v1.p.rapidapi.com/emoji/auto-emojify',
      params: { text: text },
      headers: {
        'X-RapidAPI-Key': 'e267d25c61msh5dd5789911038c9p144921jsn379659d07175',
        'X-RapidAPI-Host': 'ritekit-ritekit-v1.p.rapidapi.com'
      }
    };

    return axios.request(options).then(function (response) {
      return response.data.text;
    }).catch(function (error) {
      console.error(error);
    });
  }

  hashtagifyText(text) {
      const options = {
      method: 'GET',
      url: 'https://ritekit-ritekit-v1.p.rapidapi.com/stats/auto-hashtag',
      params: { post: text, maxhashtags: 30, hashtagposition: 'auto' },
      headers: {
        'X-RapidAPI-Key': 'e267d25c61msh5dd5789911038c9p144921jsn379659d07175',
        'X-RapidAPI-Host': 'ritekit-ritekit-v1.p.rapidapi.com'
      }
    };

    return axios.request(options).then(function (response) {
      return response.data.post;
    }).catch(function (error) {
      console.error(error);
    });
  }

  getTrendingHashtags(){
    const options = {
      method: 'GET',
      url: 'https://ritekit-ritekit-v1.p.rapidapi.com/search/trending',
      params: { green: 1 },
      headers: {
        'X-RapidAPI-Key': 'e267d25c61msh5dd5789911038c9p144921jsn379659d07175',
        'X-RapidAPI-Host': 'ritekit-ritekit-v1.p.rapidapi.com'
      }
    };

    return axios.request(options).then(function (response) {
      return response.data;
    }).catch(function (error) {
      console.error(error);
    });
  }

  getEmojis(caption) {
    return axios.get(API_URL + `emojis/${caption}`, { headers: authHeader() })
    .then((response) => {
      return response.data
    })
  }
}

function getHashtag(element) {
  const converted = addHashtag(element)
  if(!converted) return;

  const tag = { hashtag: converted, type: 'generated' }
  return tag;
}

function cleanKeyword(keyword) {
  keyword = keyword.replace(/[^a-zA-Z]/g, '')
  keyword = keyword.replace(/ /g, "");
  return keyword;
}
 
 function addHashtag(string) {
  const newString = cleanKeyword(string);
  if(newString == "" || newString.length < 3) return;
  const words = newString.split(",");
  for (let i = 0; i < words.length; i++) {
   words[i] = "#" + words[i];
  }
  return words.join(" ");
 }

export default new CaptionService();